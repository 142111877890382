import { cn } from "../../../../../../util/cn";

type CardWrapperProps = {
  children: any;
  className?: string;
};

const CardWrapper = ({ children, className = "" }: CardWrapperProps) => {
  return (
    <div
      className={cn(
        "flex flex-col w-1/2 lg:w-full md:w-full bg-[#FFFFFF] h-auto min-h-[200px] rounded-[20px] p-[30px]",
        className,
      )}
      style={{
        boxShadow: "0 0 34px rgba(30, 89, 109, 0.04)",
      }}
    >
      {children}
    </div>
  );
};

export default CardWrapper;
