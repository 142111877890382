import { useState } from "react";
import { cn } from "../../../../../../util/cn";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import toast from "react-hot-toast";
import { getSpeakWithAccountExecutiveSchema } from "../../../../../../schemas/SpeakWithAccountExecutive";
import Input from "../../../../../custom-ui/new-ui/Input";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import TextArea from "../../../../../custom-ui/new-ui/TextArea";
import ToastAlert from "../../../../../custom-ui/toast-alert/ToastAlert";
import { AccountExecutiveLeads } from "../../../../../../dataTypes/AccountExecutiveLeads";
import { useAuth } from "../../../../../../providers/auth";
import { createNewEntryForAccountExecutiveLeads } from "../../../../../../data/accountExecutiveLeads";

type TSpeakWithAccountExecutiveModalProps = {
  onCancel: () => void;
  onDone: () => void;
  type: "request" | "cancel";
  source:
    | "annualSubscriptionSignup"
    | "annualSubscriptionRenewal"
    | "annualSubscriptionCancel";
};

const SpeakWithAccountExecutiveModal = ({
  onCancel,
  onDone,
  type,
  source,
}: TSpeakWithAccountExecutiveModalProps) => {
  const { user } = useAuth();
  const employerId = user?.claims?.employerId;

  // @ts-ignore
  const [formSchema] = useState(getSpeakWithAccountExecutiveSchema());

  type FormValues = z.infer<
    ReturnType<typeof getSpeakWithAccountExecutiveSchema>
  >;

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      fullName: "",
      email: "",
      phoneNumber: "",
      message: "",
    },
    reValidateMode: "onBlur",
  });

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    reset,
    trigger,
    watch,
    formState: { errors, isSubmitting },
  } = form;

  const onSubmit = async ({ ...values }: FormValues) => {
    const { fullName, email, phoneNumber, message, ...restValues } = values;

    const accountExecutiveLeadsData: AccountExecutiveLeads = {
      fullName,
      email,
      phoneNumber,
      message,
      employerId: employerId,
      createdAt: new Date(),
      type: type, // "request" | "cancel"
      source: source,
    };

    await createNewEntryForAccountExecutiveLeads(accountExecutiveLeadsData)
      .then(() => {
        toast.success("Your form has been submitted successfully");
        setTimeout(() => {
          onDone();
        }, 3000);
      })
      .catch(() => {
        toast.error("There was an error submitting the form");
      });
  };

  return (
    <>
      <div className={cn("flex flex-col items-center overflow-hidden", {})}>
        <form className="mt-[30px] w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-[30px]">
            <Controller
              control={control}
              name="fullName"
              render={(field) => (
                <Input
                  {...field}
                  display="Full Name"
                  placeholder="Enter Full Name"
                  readOnly={false}
                  error={errors?.fullName?.message}
                  required={true}
                />
              )}
            />

            <Controller
              control={control}
              name="email"
              render={(field) => (
                <Input
                  {...field}
                  display="Email"
                  placeholder="Enter Email"
                  readOnly={false}
                  error={errors?.email?.message}
                  required={true}
                />
              )}
            />

            <div className="relative mb-7">
              <PhoneInputWithCountry
                className="peer mt-1 block w-full px-3 py-2 inp sm:text-sm outline-none ring-0 border-none"
                style={{ boxShadow: "none" }}
                name="phoneNumber"
                international
                defaultCountry="US"
                countryCallingCodeEditable={false}
                control={control}
                //onChange={async () => await trigger("phoneNumber")}
                onBlur={async () => await trigger("phoneNumber")}
                rules={{
                  required: "Phone number is required",
                  pattern: {
                    value: /^\+?[1-9]\d{1,14}$/,
                    message: "Invalid phone number",
                  },
                }}
              />
              <label
                htmlFor="phoneNumber"
                className="absolute -top-4 text-sm text-[16px] text-[#1E596D] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
              >
                Phone Number <span className="mr-1 text-red-600">*</span>
              </label>
              {errors.phoneNumber && (
                <p className="mt-1 text-sm text-left text-red-600">
                  {errors.phoneNumber.message}
                </p>
              )}
            </div>

            <Controller
              control={control}
              name="message"
              render={(field) => (
                <>
                  <TextArea
                    {...field}
                    display="Message"
                    placeholder="Please write here"
                    labelClassName="text-[16px] text-[#1E596D] font-[400] mb-[10px]"
                    readOnly={false}
                    error={errors?.message?.message}
                    maxLength={500}
                    required={true}
                  />
                </>
              )}
            />
          </div>

          <div className="flex flex-row justify-center items-center gap-[15px] mt-[50px]">
            <button
              className="flex justify-center items-center h-[60px] w-[260px] py-[25px] px-[60px] rounded-[70px] bg-[#15415E] text-[#FFFFFF] text-[20px] leading-[24.76px] font-[400] font-[Gilroy-Bold]"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
      <ToastAlert />
    </>
  );
};

export default SpeakWithAccountExecutiveModal;
