import {
  customDataRouteObject,
  customRoute,
} from "../../dataTypes/ReactRouter";
import GenerateCohorts from "../../pages/cohorts/GenerateCohorts/GenerateCohorts";
import IndexPage from "../../pages";
import CatalogDetails from "../../components/academy/pages/CatalogDetail";
import EmployerDetailsPage from "../../pages/employers/employer-details";
import JobsManagementPage from "../../pages/jobs/jobsManagement";
import JobPage from "../../pages/jobs/JobPage";
import JobsNewManagementPage from "../../pages/jobs-new/jobsNewManagementPage";
import JobNewPage from "../../pages/jobs-new/JobNewPage";
import SeekerManagement from "../../pages/seekers/SeekerManagement";
import UserManagementPage from "../../pages/users/user-management";
import CohortManagementPage from "../../pages/cohorts/CohortManagement";
import EmployeePage from "../../components/employee/details/pages/EmployeePage";
import JobOfferDetailsPage from "../../components/jobs/pages/JobOfferDetailsPage";
import UserStatusPage from "../../pages/users/user-status-message";
import NotFoundPage from "../../pages/404";
import EmployersPage from "../../pages/employers/employers";
import Employers_NewUI from "../../pages/employers/EmployerNewUi/Employers_NewUI";
import { primaryPublicChildren } from "../publicRoutes";
import { sharedChildren } from "../sharedRoutes";

import {
  EmployerDetailsChildRoutes,
  JobListingChildRoutes,
  JobNewListingChildRoutes,
  SeekerDetailsChildRoutes,
  AssessmentDetailsChildren,
  DashboardChildRoutes,
  AssessementChildren,
  UserDetailsChildRoutes,
  OfferDetailsChildRoutes,
  EmployerDetailsChildRoutesNew,
} from "../internal/internalChildrenRoutes";

import SidebarWrapper from "../../components/layout/sidebar/SidebarWrapper";
import EmployerSidebarHeader from "../../components/layout/sidebar/EmployerSidebarHeader";
import EmployeeSidebarHeader from "../../components/layout/sidebar/EmployeeSidebarHeader";
import AcademySidebarHeader from "../../components/layout/sidebar/AcademySidebarHeader";
import JobsSidebarHeader from "../../components/layout/sidebar/JobSidebarHeader";
import JobNewSidebarHeader from "../../components/layout/sidebar/JobNewSidebarHeader";
import OfferSidebarHeader from "../../components/layout/sidebar/OfferSidebarHeader";
import { InstantSearch } from "react-instantsearch-hooks-web";
import { InstantSearch as InstantSearchNew } from "react-instantsearch"; // Implemented for New Job due to cache issue
import algoliasearch from "algoliasearch/lite";
import AcademyCatalog from "../../components/academy/pages/AcademyCatalog";
import SeekerPage from "../../components/seeker/details/pages/SeekerPage";
import SeekerSidebarHeader from "../../components/layout/sidebar/SeekerSidebarHeader";
import LearningPage from "../../pages/learning/LearningPage";
import JobApplicantsPage from "../../components/jobs/pages/JobApplicantsPage";

import {
  ApplicantChildRoutes,
  EmployeeChildRoutes,
} from "../internal/internalChildrenRoutes";

import ApplicationSidebarHeader from "../../components/layout/sidebar/ApplicationSidebarHeader";
import OfferLetterPreview from "../../components/jobs/pages/OfferLetterPreview";
import { ALL_ADMIN } from "../navbarRoutes";
import DashboardHeader from "../../components/layout/sidebar/DashboardHeader";
import DashboardPage from "../../pages/dashboard/dashboard";
import { ErrorBoundary } from "react-error-boundary";
import Fallback from "../../components/fallback/Fallback";
import AcademyOutlet from "../../components/academy/pages/AcademyOutlet";
import AcademyPageHeader from "../../components/layout/sidebar/AcademyPageHeader";
import UserDetailsSidebarHeader from "../../components/layout/sidebar/UserManagementSidebarHeader";
import UserDetailsPage from "../../pages/users/user-details";
import JobsPReview from "../../components/jobs/pages/JobsPreview";
import OfferDetails from "../../components/offers/parts/OfferDetails";

import { AcceleratorDetailsChildren } from "../internal/internalChildrenRoutes";
import Accelerator from "../../components/academy/pages/Accelerators/Accelerator";
import AcceleratorSidebarHeader from "../../components/layout/sidebar/AcceleratorSidebarHeader";
import { ExternalOfferChildRoutes } from "../external/childrenRoutes";
import ExternalJobOfferDetailsPage from "../../components/jobs/pages/ExternalJobOfferDetailsPage";
import EmailVerificationPage from "../../pages/shared/EmailVerificationPage";
import SeekerMultifactorEnrollmentPage from "../../pages/seekerPages/SeekerMultifactorEnrollmentPage";
import SeekerOnboardingPage from "../../pages/seekerPages/SeekerOnboardingPage";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useState, useEffect } from "react";
import { sharedChildren_newUi } from "../sharedRoutes_new_ui";
import { manageConfiguration } from "../../data/configuration";
import { primaryPublicChildren_newui } from "../publicRoutes";

const searchClient = (() => {
  if (!process.env.REACT_APP_ALGOLIA_APP_ID) {
    throw new Error(
      "REACT_APP_ALGOLIA_APP_ID environment variable not defined",
    );
  }
  if (!process.env.REACT_APP_ALGOLIA_API_KEY) {
    throw new Error(
      "REACT_APP_ALGOLIA_API_KEY environment variable not defined",
    );
  }

  return algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_API_KEY,
  );
})();

export const primaryRouteChildren: customDataRouteObject[] = [
  ...sharedChildren,
  ...primaryPublicChildren,

  {
    id: "Learning",
    name: "Learning",
    path: "/learning",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SidebarWrapper
          options={AssessementChildren}
          headerSection={AcademyPageHeader}
        >
          <AcademyOutlet />
        </SidebarWrapper>
      </ErrorBoundary>
    ),
    children: AssessementChildren,
  },
  {
    id: "Assessment Details",
    name: "Details",
    path: "/learning/assessment/:assessmentId",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SidebarWrapper
          options={AssessmentDetailsChildren}
          headerSection={AcademySidebarHeader}
        >
          <LearningPage />
        </SidebarWrapper>
      </ErrorBoundary>
    ),
    children: AssessmentDetailsChildren,
  },
  {
    id: "Accelerator Details",
    name: "Details",
    path: "/learning/accelerator/:acceleratorId",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SidebarWrapper
          options={AcceleratorDetailsChildren}
          headerSection={AcceleratorSidebarHeader}
        >
          <Accelerator />
        </SidebarWrapper>
      </ErrorBoundary>
    ),
    children: AcceleratorDetailsChildren,
  },
  {
    id: "Academy",
    name: "Academy",
    path: "/academy",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <InstantSearch
          searchClient={searchClient}
          indexName="AcademyAssessments"
        >
          <AcademyCatalog />
        </InstantSearch>
      </ErrorBoundary>
    ),
  },
  {
    id: "Catalog Details",
    name: "Details",
    path: "/academy/:assessmentId/",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <CatalogDetails />
      </ErrorBoundary>
    ),
  },

  {
    id: "Dashboard",
    name: "Dashboard",
    path: "/dashboard/",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SidebarWrapper
          options={DashboardChildRoutes}
          headerSection={DashboardHeader}
        >
          <DashboardPage />
        </SidebarWrapper>
      </ErrorBoundary>
    ),
    children: DashboardChildRoutes,
  },

  {
    id: "Employers",
    name: "Employers",
    path: "/employers/",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <EmployersPage />
      </ErrorBoundary>
    ),
  },
  {
    id: "Employer Details",
    name: "Details",
    path: "/employers/:employerId/",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <InstantSearch searchClient={searchClient} indexName="UserQueries">
          <SidebarWrapper
            options={EmployerDetailsChildRoutes}
            headerSection={EmployerSidebarHeader}
          >
            <EmployerDetailsPage />
          </SidebarWrapper>
        </InstantSearch>
      </ErrorBoundary>
    ),

    children: EmployerDetailsChildRoutes,
  },
  // placeholder route
  {
    id: "Employer new offer",
    name: "New Offer",
    path: "/offers/new/:applicationId",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <div className="">New offer </div>
      </ErrorBoundary>
    ),
  },
  {
    id: "Jobs",
    name: "Jobs",
    path: "/jobs/",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <InstantSearch searchClient={searchClient} indexName="JobDescriptions">
          <JobsManagementPage />
        </InstantSearch>
      </ErrorBoundary>
    ),
  },
  {
    id: "Job Details",
    name: "Details",
    path: "/jobs/:id/:employerId",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SidebarWrapper
          options={JobListingChildRoutes}
          headerSection={JobsSidebarHeader}
        >
          <JobPage />
        </SidebarWrapper>
      </ErrorBoundary>
    ),
    children: JobListingChildRoutes,
  },
  {
    id: "Jobs (New)",
    name: "Jobs New",
    path: "/jobs-new/",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <InstantSearchNew searchClient={searchClient} indexName="JobDetailsNewCollection">
          <JobsNewManagementPage />
        </InstantSearchNew>
      </ErrorBoundary>
    ),
  },
  {
    id: "Job Details (New)",
    name: "Details (New)",
    path: "/jobs-new/:id/:employerId",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SidebarWrapper
          options={JobNewListingChildRoutes}
          headerSection={JobNewSidebarHeader}
        >
          <JobNewPage />
        </SidebarWrapper>
      </ErrorBoundary>
    ),
    children: JobNewListingChildRoutes,
  },
  {
    id: "Offer Letter",
    name: "Offer Letter Preview",
    path: "/offer-letter/:offerId/",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <OfferLetterPreview />
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
  },
  {
    id: "Seeker",
    name: "Seeker",
    path: "/seekers/",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <InstantSearch searchClient={searchClient} indexName="UserQueries">
          <SeekerManagement />
        </InstantSearch>
      </ErrorBoundary>
    ),
  },
  {
    id: "Seeker Details",
    name: "Details",
    path: "/seekers/:seekerId/",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SidebarWrapper
          options={SeekerDetailsChildRoutes}
          headerSection={SeekerSidebarHeader}
        >
          <SeekerPage />
        </SidebarWrapper>
      </ErrorBoundary>
    ),
    children: SeekerDetailsChildRoutes,
  },
  // Needs to be to work on External side
  {
    id: "Employer Offer Details  ",
    name: "Employer Offer Details ",
    path: "offer-details/:offerId/",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SidebarWrapper
          options={ExternalOfferChildRoutes}
          headerSection={OfferSidebarHeader}
        >
          <ExternalJobOfferDetailsPage />
        </SidebarWrapper>
      </ErrorBoundary>
    ),
    children: ExternalOfferChildRoutes,
  },

  {
    id: "Users",
    name: "Users",
    path: "/users/",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <InstantSearch searchClient={searchClient} indexName="UserQueries">
          <UserManagementPage />
        </InstantSearch>
      </ErrorBoundary>
    ),
  },
  {
    id: "User Details",
    name: "User Details",
    path: "/users/:userId",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SidebarWrapper
          options={UserDetailsChildRoutes}
          headerSection={UserDetailsSidebarHeader}
        >
          <UserDetailsPage />
        </SidebarWrapper>
      </ErrorBoundary>
    ),
    children: UserDetailsChildRoutes,
  },
  {
    id: "User Status",
    name: "Status",
    path: "/user-status-message",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <UserStatusPage />
      </ErrorBoundary>
    ),
  },
  /// cohorts routing

  {
    id: "Generate Cohort",
    name: " Generate Cohort",
    path: "/cohort",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <InstantSearch searchClient={searchClient} indexName="UserQueries">
          <GenerateCohorts />
        </InstantSearch>
      </ErrorBoundary>
    ),
  },
  {
    id: "Cohort",
    name: "Cohort",
    path: "/cohort/listing",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <InstantSearch searchClient={searchClient} indexName="UserQueries">
          <CohortManagementPage />
        </InstantSearch>
      </ErrorBoundary>
    ),
  },
  {
    id: "Top Applications ",
    name: "Applications ",
    path: "applications/:applicationId/",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SidebarWrapper
          headerSection={ApplicationSidebarHeader}
          options={ApplicantChildRoutes}
        >
          <JobApplicantsPage />
        </SidebarWrapper>
      </ErrorBoundary>
    ),
    children: ApplicantChildRoutes,
  },
  {
    id: "Employer Employees",
    name: "Employees",
    path: "employee/:employeeId/",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SidebarWrapper
          headerSection={EmployeeSidebarHeader}
          options={EmployeeChildRoutes}
        >
          <EmployeePage />
        </SidebarWrapper>
      </ErrorBoundary>
    ),
    children: EmployeeChildRoutes,
  },
  {
    id: "jobPreview",
    name: "Job Preview",
    path: "preview/:jobId/:positionId",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <JobsPReview />
      </ErrorBoundary>
    ),
  },
  {
    id: "Offer Details ",
    name: "Offers ",
    path: "offers/:offerId/",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SidebarWrapper
          headerSection={OfferSidebarHeader}
          options={OfferDetailsChildRoutes}
        >
          <JobOfferDetailsPage />
        </SidebarWrapper>
      </ErrorBoundary>
    ),
    children: OfferDetailsChildRoutes,
  },
  // SeekerEmailVerification placeholder route added to avoid 404 error on seeker pages until we decide on permanent solution
  {
    id: "SeekerEmailVerification",
    name: "Email Verification",
    path: "/email-verification",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <EmailVerificationPage />
      </ErrorBoundary>
    ),
  },
  // SeekerMultifactorEnrollmentPage placeholder route added to avoid 404 error on seeker pages until we decide on permanent solution
  {
    id: "Seeker MFA Enroll",
    name: "Multifactor Enrollment",
    path: "/registration/multifactor-enrollment",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SeekerMultifactorEnrollmentPage />
      </ErrorBoundary>
    ),
  },

  {
    id: "SeekerOnboarding",
    name: "Career Seeker Onboarding",
    path: "/onboarding",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SeekerOnboardingPage />
      </ErrorBoundary>
    ),
  },
];

export const internalRoute: customRoute[] = [
  {
    id: "Index",
    name: "Index",
    path: "/",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <IndexPage />
      </ErrorBoundary>
    ),
    errorElement: <NotFoundPage />,
    children: primaryRouteChildren,
  },
];


export const primaryRouteChildren_newUi: customDataRouteObject[] = [
  ...sharedChildren_newUi,
  ...primaryPublicChildren_newui,

  {
    id: "Learning",
    name: "Learning",
    path: "/learning",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SidebarWrapper
          options={AssessementChildren}
          headerSection={AcademyPageHeader}
        >
          <AcademyOutlet />
        </SidebarWrapper>
      </ErrorBoundary>
    ),
    children: AssessementChildren,
  },
  {
    id: "Assessment Details",
    name: "Details",
    path: "/learning/assessment/:assessmentId",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SidebarWrapper
          options={AssessmentDetailsChildren}
          headerSection={AcademySidebarHeader}
        >
          <LearningPage />
        </SidebarWrapper>
      </ErrorBoundary>
    ),
    children: AssessmentDetailsChildren,
  },
  {
    id: "Accelerator Details",
    name: "Details",
    path: "/learning/accelerator/:acceleratorId",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SidebarWrapper
          options={AcceleratorDetailsChildren}
          headerSection={AcceleratorSidebarHeader}
        >
          <Accelerator />
        </SidebarWrapper>
      </ErrorBoundary>
    ),
    children: AcceleratorDetailsChildren,
  },
  {
    id: "Academy",
    name: "Academy",
    path: "/academy",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <InstantSearch
          searchClient={searchClient}
          indexName="AcademyAssessments"
        >
          <AcademyCatalog />
        </InstantSearch>
      </ErrorBoundary>
    ),
  },
  {
    id: "Catalog Details",
    name: "Details",
    path: "/academy/:assessmentId/",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <CatalogDetails />
      </ErrorBoundary>
    ),
  },

  {
    id: "Dashboard",
    name: "Dashboard",
    path: "/dashboard/",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SidebarWrapper
          options={DashboardChildRoutes}
          headerSection={DashboardHeader}
        >
          <DashboardPage />
        </SidebarWrapper>
      </ErrorBoundary>
    ),
    children: DashboardChildRoutes,
  },
  {
    id: "Employers",
    name: "Employers",
    path: "/employers/",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <EmployersPage />
      </ErrorBoundary>
    ),
  },
  {
    id: "Employersnew",
    name: "Employers-new",
    path: "/employers-new/",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <Employers_NewUI />
      </ErrorBoundary>
    ),
  },
  {
    id: "Employer Details",
    name: "Details",
    path: "/employers/:employerId/",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <InstantSearch searchClient={searchClient} indexName="UserQueries">
          <SidebarWrapper
            options={EmployerDetailsChildRoutes}
            headerSection={EmployerSidebarHeader}
          >
            <EmployerDetailsPage />
          </SidebarWrapper>
        </InstantSearch>
      </ErrorBoundary>
    ),

    children: EmployerDetailsChildRoutes,
  },

  {
    id: "Employer Details New",
    name: "Details-new",
    path: "/employers-new/:employerId/",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <InstantSearch searchClient={searchClient} indexName="UserQueries">
          <SidebarWrapper
            options={EmployerDetailsChildRoutesNew}
            headerSection={EmployerSidebarHeader}
          >
            <EmployerDetailsPage />
          </SidebarWrapper>
        </InstantSearch>
      </ErrorBoundary>
    ),

    children: EmployerDetailsChildRoutesNew,
  },
  // placeholder route
  {
    id: "Employer new offer",
    name: "New Offer",
    path: "/offers/new/:applicationId",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <div className="">New offer </div>
      </ErrorBoundary>
    ),
  },
  {
    id: "Jobs",
    name: "Jobs",
    path: "/jobs/",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <InstantSearch searchClient={searchClient} indexName="JobDescriptions">
          <JobsManagementPage />
        </InstantSearch>
      </ErrorBoundary>
    ),
  },
  {
    id: "Job Details",
    name: "Details",
    path: "/jobs/:id/:employerId",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SidebarWrapper
          options={JobListingChildRoutes}
          headerSection={JobsSidebarHeader}
        >
          <JobPage />
        </SidebarWrapper>
      </ErrorBoundary>
    ),
    children: JobListingChildRoutes,
  },

  {
    id: "Jobs-(New)",
    name: "Jobs New",
    path: "/jobs-new/",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <InstantSearchNew searchClient={searchClient} indexName="JobDetailsNewCollection">
          <JobsNewManagementPage />
        </InstantSearchNew>
      </ErrorBoundary>
    ),
  },
  {
    id: "Job Detail (New)",
    name: "Details (New)",
    path: "/jobs-new/:id/:employerId",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SidebarWrapper
          options={JobNewListingChildRoutes}
          headerSection={JobNewSidebarHeader}
        >
          <JobNewPage />
        </SidebarWrapper>
      </ErrorBoundary>
    ),
    children: JobNewListingChildRoutes,
  },
  {
    id: "Offer Letter",
    name: "Offer Letter Preview",
    path: "/offer-letter/:offerId/",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <OfferLetterPreview />
      </ErrorBoundary>
    ),
    viewer: ALL_ADMIN,
  },
  {
    id: "Seeker",
    name: "Seeker",
    path: "/seekers/",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <InstantSearch searchClient={searchClient} indexName="UserQueries">
          <SeekerManagement />
        </InstantSearch>
      </ErrorBoundary>
    ),
  },
  {
    id: "Seeker Details",
    name: "Details",
    path: "/seekers/:seekerId/",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SidebarWrapper
          options={SeekerDetailsChildRoutes}
          headerSection={SeekerSidebarHeader}
        >
          <SeekerPage />
        </SidebarWrapper>
      </ErrorBoundary>
    ),
    children: SeekerDetailsChildRoutes,
  },
  // Needs to be to work on External side
  {
    id: "Employer Offer Details  ",
    name: "Employer Offer Details ",
    path: "offer-details/:offerId/",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SidebarWrapper
          options={ExternalOfferChildRoutes}
          headerSection={OfferSidebarHeader}
        >
          <ExternalJobOfferDetailsPage />
        </SidebarWrapper>
      </ErrorBoundary>
    ),
    children: ExternalOfferChildRoutes,
  },

  {
    id: "Users",
    name: "Users",
    path: "/users/",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <InstantSearch searchClient={searchClient} indexName="UserQueries">
          <UserManagementPage />
        </InstantSearch>
      </ErrorBoundary>
    ),
  },
  {
    id: "User Details",
    name: "User Details",
    path: "/users/:userId",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SidebarWrapper
          options={UserDetailsChildRoutes}
          headerSection={UserDetailsSidebarHeader}
        >
          <UserDetailsPage />
        </SidebarWrapper>
      </ErrorBoundary>
    ),
    children: UserDetailsChildRoutes,
  },
  {
    id: "User Status",
    name: "Status",
    path: "/user-status-message",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <UserStatusPage />
      </ErrorBoundary>
    ),
  },
  /// cohorts routing

  {
    id: "Generate Cohort",
    name: " Generate Cohort",
    path: "/cohort",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <InstantSearch searchClient={searchClient} indexName="UserQueries">
          <GenerateCohorts />
        </InstantSearch>
      </ErrorBoundary>
    ),
  },
  {
    id: "Cohort",
    name: "Cohort",
    path: "/cohort/listing",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <InstantSearch searchClient={searchClient} indexName="UserQueries">
          <CohortManagementPage />
        </InstantSearch>
      </ErrorBoundary>
    ),
  },
  {
    id: "Top Applications ",
    name: "Applications ",
    path: "applications/:applicationId/",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SidebarWrapper
          headerSection={ApplicationSidebarHeader}
          options={ApplicantChildRoutes}
        >
          <JobApplicantsPage />
        </SidebarWrapper>
      </ErrorBoundary>
    ),
    children: ApplicantChildRoutes,
  },
  {
    id: "Employer Employees",
    name: "Employees",
    path: "employee/:employeeId/",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SidebarWrapper
          headerSection={EmployeeSidebarHeader}
          options={EmployeeChildRoutes}
        >
          <EmployeePage />
        </SidebarWrapper>
      </ErrorBoundary>
    ),
    children: EmployeeChildRoutes,
  },
  {
    id: "jobPreview",
    name: "Job Preview",
    path: "preview/:jobId/:positionId",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <JobsPReview />
      </ErrorBoundary>
    ),
  },
  {
    id: "Offer Details ",
    name: "Offers ",
    path: "offers/:offerId/",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SidebarWrapper
          headerSection={OfferSidebarHeader}
          options={OfferDetailsChildRoutes}
        >
          <JobOfferDetailsPage />
        </SidebarWrapper>
      </ErrorBoundary>
    ),
    children: OfferDetailsChildRoutes,
  },
  // SeekerEmailVerification placeholder route added to avoid 404 error on seeker pages until we decide on permanent solution
  {
    id: "SeekerEmailVerification",
    name: "Email Verification",
    path: "/email-verification",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <EmailVerificationPage />
      </ErrorBoundary>
    ),
  },
  // SeekerMultifactorEnrollmentPage placeholder route added to avoid 404 error on seeker pages until we decide on permanent solution
  {
    id: "Seeker MFA Enroll",
    name: "Multifactor Enrollment",
    path: "/registration/multifactor-enrollment",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SeekerMultifactorEnrollmentPage />
      </ErrorBoundary>
    ),
  },

  {
    id: "SeekerOnboarding",
    name: "Career Seeker Onboarding",
    path: "/onboarding",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SeekerOnboardingPage />
      </ErrorBoundary>
    ),
  },
];

export const internalRoute_newUi: customRoute[] = [
  {
    id: "Index",
    name: "Index",
    path: "/",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <IndexPage />
      </ErrorBoundary>
    ),
    errorElement: <NotFoundPage />,
    children: primaryRouteChildren_newUi,
  },
];

export const CreateInternalRoutes = () => {
  const [newConfig, setNewConfig] = useState(false);
  const [routes, setRoutes] = useState(createBrowserRouter(internalRoute));
  const fetchSkills = async () => {
    const data = await manageConfiguration();
    if (data[0]?.manage_new_ui) {
      setNewConfig(data[0]?.manage_new_ui);
    } else {
    }
  };
  useEffect(() => {

    fetchSkills();

  }, [])

  useEffect(() => {
    setRoutes(createBrowserRouter(newConfig ? internalRoute_newUi : internalRoute))
  }, [newConfig])


  return <RouterProvider router={routes} />;
}