import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useLookup } from "../../../../data/lookup";
// import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { employerInfoSchema } from "./Schema/registrationSchema";
import { useCompanyByDomain } from "../../../../data/employer";

import "./style.css";
import { useEffect } from "react";
import DialogWrapper from "../../../custom-ui/dialog/Dialog_NewUI";

const VALIDATION_MESSAGES = {
  requiredCompanyName: "Please enter a Company Name",
  requiredCompanyWebsite: "Please enter a Company Website",
  requiredEmailDomain: "Please enter a valid Company Email Domain",
  requiredHeadquartersLocation: "Please enter a Headquarters Location",
};

function InputField({ label, name, register, requiredMessage, errors }: any) {
  return (
    <div className="relative mb-7">
      <input
        type="text"
        name={name}
        placeholder=" "
        ref={register({ required: requiredMessage })}
        className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
        style={{ boxShadow: "none", borderColor: errors[name] ? "red" : "" }}
      />
      <label
        htmlFor={name}
        className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-focus:-top-4"
      >
        {label} <span className="text-red-600">*</span>
      </label>
      {errors[name] && (
        <p className="mt-1 text-sm text-red-600">{errors[name].message}</p>
      )}
    </div>
  );
}

function EmployerRegistrationStepOne({ onDone }: any) {
  // const navigate = useNavigate();
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [domainCheckTrigger, setDomainCheckTrigger] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { data: companyJobsToFill = [] } = useLookup("companyJobsToFill");
  const { data: companySize = [] } = useLookup("companySize");
  const { data: companyHiringNeeds = [] } = useLookup("companyHiringNeeds");
  const {
    handleSubmit,
    setValue,
    register,
    formState: { errors },
    watch,
    setError,
    trigger,
  } = useForm({
    defaultValues: {
      companyName: "",
      companyUrl: "",
      domain: "",
      location: "",
      companySize: companySize[0]?.code || "",
      jobsToFill: companyJobsToFill[0]?.code || "",
      hiringNeeds: companyHiringNeeds[0]?.code || "",
    },
    resolver: zodResolver(employerInfoSchema),
  });

  const companyName = watch("companyName");
  const location = watch("location");
  const companyUrl = watch("companyUrl");
  const domain = watch("domain");
  const selectedCompanySize = watch("companySize");
  const selectedJobsToFill = watch("jobsToFill");
  const selectedHiringNeeds = watch("hiringNeeds");

  const isFormComplete =
    companyName &&
    companyUrl &&
    domain &&
    location &&
    selectedCompanySize &&
    selectedJobsToFill &&
    selectedHiringNeeds;

  const { data: existingDomain } = useCompanyByDomain(
    domain.toLowerCase(),
    domainCheckTrigger,
  );

  // const domainExists = () => {
  //   if (Array.isArray(existingDomain)) {
  //     return !!existingDomain.length;
  //   }
  //   return false;
  // };

  const saveEmployerInfoAndAdvanceForm = async (data: any) => {
    if (Array.isArray(existingDomain) && !!existingDomain.length) {
      setIsModalVisible(true);
      return;
    }
    if (!isFormComplete) {
      // Set errors on incomplete fields
      if (!companyName)
        setError("companyName", { message: "This field is required" });
      if (!companyUrl)
        setError("companyUrl", { message: "This field is required" });
      if (!domain) setError("domain", { message: "This field is required" });
      localStorage.setItem("employerCompany", JSON.stringify({ ...data }));
      if (!location)
        setError("location", { message: "This field is required" });

      return;
    }

    try {
      setPending(true);
      await onDone({
        ...data,
        companySize: selectedCompanySize,
        jobsToFill: selectedJobsToFill,
        hiringNeeds: selectedHiringNeeds,
      });
    } catch (error) {
      setFormAlert("An error occurred, please try again.");
    } finally {
      setPending(false);
    }
  };

  const setIntialValuesToForm = (
    companySizeData: string,
    companyHiringNeeds: string,
    companyJobsToFill: string,
  ) => {
    if (!companySizeData && !companyHiringNeeds && !companyJobsToFill) return;

    setValue("companySize", companySizeData ?? "");
    setValue("hiringNeeds", companyHiringNeeds ?? "");
    setValue("jobsToFill", companyJobsToFill ?? "");
  };

  useEffect(() => {
    if (
      companySize.length &&
      companyHiringNeeds.length &&
      companyJobsToFill.length &&
      !isInitialized
    ) {
      setIntialValuesToForm(
        companySize[0].code,
        companyHiringNeeds[0].code,
        companyJobsToFill[0].code,
      );
      setIsInitialized(true);
    }
  }, [companyHiringNeeds, companyJobsToFill, companySize, isInitialized]);

  function closeDeleteModal(): void {
    setIsModalVisible(false);
  }

  return (
    <>
      <div className="max-w-2xl mx-auto p-6 bg-white">
        <form
          className="space-y-8"
          onSubmit={handleSubmit(saveEmployerInfoAndAdvanceForm)}
          autoComplete="off"
        >
          <InputField
            label="Company Name"
            name="companyName"
            register={register}
            requiredMessage={VALIDATION_MESSAGES.requiredCompanyName}
            errors={errors}
          />
          <InputField
            label="Company Website"
            name="companyUrl"
            register={register}
            requiredMessage={VALIDATION_MESSAGES.requiredCompanyWebsite}
            errors={errors}
          />
          {/* <InputField
          label="Company Email Domain"
          name="domain"
          register={register}
          requiredMessage={VALIDATION_MESSAGES.requiredEmailDomain}
          errors={errors}
        /> */}
          <div className="relative mb-7">
            <input
              type="text"
              name="domain"
              placeholder=" "
              ref={register({
                required: VALIDATION_MESSAGES.requiredEmailDomain,
              })}
              className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm"
              style={{
                boxShadow: "none",
                borderColor: errors["domain"] ? "red" : "",
              }}
              onBlur={() => {
                setDomainCheckTrigger(true);
                trigger("domain");
              }}
              onFocus={() => setDomainCheckTrigger(false)}
            />
            <label
              htmlFor="Company Email Domain"
              className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-focus:-top-4"
            >
              Company Email Domain <span className="text-red-600">*</span>
            </label>
            {errors["domain"] && (
              <p className="mt-1 text-sm text-red-600">
                {errors["domain"].message}
              </p>
            )}
            {/* {domainExists() && (
              <div className="rounded bg-amber-200 flex justify-center flex-col">
                <p className="text-center text-sm font-bold">
                  Email Domain Already Exist.
                </p>
              </div>
            )} */}
          </div>

          <InputField
            label="Headquarters Location"
            name="location"
            register={register}
            requiredMessage={VALIDATION_MESSAGES.requiredHeadquartersLocation}
            errors={errors}
          />

          <div className="relative mb-7">
            <select
              name="companySize"
              className="peer mt-1 block w-full px-3 inp py-2 rounded-none sm:text-sm custom-dropdown"
              ref={register}
              style={{ fontSize: "13px", boxShadow: "none", color: "black" }}
            >
              <option style={{ color: "black" }} value="" disabled>
                Select Company Size
              </option>
              {companySize.map((item: any) => (
                <option key={item.id} value={item.code}>
                  {item.label}
                </option>
              ))}
            </select>
            <label
              htmlFor="companySize"
              className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2"
            >
              Company Size
            </label>
          </div>

          {formAlert && (
            <div className="rounded bg-red-200 p-2 text-red-600 text-center">
              {formAlert}
            </div>
          )}
          <div className="relative mb-7">
            <label
              htmlFor="jobsToFill"
              className="absolute -top-4 mb-4 text-xl text-[#15415E] bg-white px-1 transition-all duration-200"
            >
              How many jobs do you need to fill?
            </label>
            <div className="flex flex-row space-x-6 mt-6 pt-6">
              {companyJobsToFill.map((item: any) => (
                <div key={item.id} className="flex items-center">
                  <input
                    className="h-5 w-5 shadow text-instant-teams-blue-L1 bg-white rounded-full border-instant-teams-blue-Main outline-none focus:border-instant-teams-blue-Main focus:ring-1"
                    id={item.id}
                    name="jobsToFill"
                    type="radio"
                    value={item.code}
                    ref={register}
                  />
                  <label
                    className="ml-3 text-lg font-semibold text-instant-teams-blue-Main"
                    htmlFor={item.id}
                  >
                    {item.label}
                  </label>
                </div>
              ))}
            </div>
          </div>

          <div className="relative mb-7">
            <label
              htmlFor="hiringNeeds"
              className="absolute -top-4 mb-4 text-xl text-[#15415E] bg-white px-1 transition-all duration-200"
            >
              What best describes your hiring needs?
            </label>
            <div className="flex flex-row space-x-6 mt-6 pt-6">
              {companyHiringNeeds.map((item: any, index: number) => (
                <div key={item.id} className="flex items-center">
                  <input
                    className="h-5 w-5 shadow text-instant-teams-blue-L1 bg-white rounded-full border-instant-teams-blue-Main outline-none focus:border-instant-teams-blue-Main focus:ring-1"
                    id={item.id}
                    name="hiringNeeds"
                    type="radio"
                    value={item.code}
                    ref={register}
                    // defaultValue={item}
                  />
                  <label
                    className="ml-3 text-lg font-semibold text-instant-teams-blue-Main"
                    htmlFor={item.id}
                  >
                    {item.label}
                  </label>
                </div>
              ))}
            </div>
          </div>

          <div className="flex justify-center">
            <button
              type="submit"
              className={
                "py-2 px-8 text-white font-medium rounded-2xl focus:outline-none bg-[#15415E]"
              }
              style={{ width: "200px" }}
              disabled={pending}
            >
              {pending ? (
                <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
              ) : (
                "Continue"
              )}
            </button>
          </div>
        </form>
      </div>
      {isModalVisible && (
        <DialogWrapper
          title="Email Domain Already Exist"
          onClose={closeDeleteModal}
          displayCloseIcon={true}
          size="max-w-[480px]"
        >
          <p>Please get in touch with Instant Teams Helpdesk</p>
        </DialogWrapper>
      )}
    </>
  );
}

export default EmployerRegistrationStepOne;
