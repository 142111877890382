import {
  collection,
  addDoc,
  doc,
  updateDoc,
  query,
  where,
} from "firebase/firestore";
import { createQuery, db } from "../providers/database";
import { AccountExecutiveLeads } from "../dataTypes/AccountExecutiveLeads";

import { collectionNames } from "./dictionary/collectionNames";
import { useQuery } from "react-query";

const accountExecutiveLeadsRef = collection(
  db,
  collectionNames.accountExecutiveLeads,
);

// Create a new entry for AccountExecutiveLeads
export async function createNewEntryForAccountExecutiveLeads(
  data: AccountExecutiveLeads,
) {
  const newJob = await addDoc(accountExecutiveLeadsRef, {
    ...data,
  });
  return newJob.id;
}

// Update entry for AccountExecutiveLeads
export async function updateEntryForAccountExecutiveLeads(
  id: string,
  data: AccountExecutiveLeads,
) {
  const updatedJob = await updateDoc(
    doc(db, collectionNames.accountExecutiveLeads, id),
    {
      ...data,
    },
  );
  return updatedJob;
}

export function useAccountExecutiveLeads(id: string) {
  return useQuery(
    ["AccountExecutiveLeads", { id }],
    createQuery(() => doc(db, collectionNames.accountExecutiveLeads, id)),
    { enabled: !!id },
  );
}
