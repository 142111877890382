import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

type StripePaymentReturnPageProps = {};

const StripePaymentReturnPage = ({}: StripePaymentReturnPageProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const redirectTo = searchParams.get("redirectTo");

  useEffect(() => {
    setTimeout(() => {
      if (redirectTo === "currentPlan") {
        navigate("/current-plan");
      } else {
        navigate("/home");
      }
    }, 2000);
  }, []);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded shadow-md text-center max-w-md">
        <h1 className="text-2xl font-bold text-gray-800">
          Transaction Under Process
        </h1>
        <p className="mt-4 text-gray-600">
          Your transaction is currently being processed. Please do not refresh
          or close this page.
        </p>
      </div>
    </div>
  );
};

export default StripePaymentReturnPage;
