import React from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { AddUserEmployerSideSchema } from "../../../../../../schemas/AddUserEmployerSideSchema";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { createInvitation } from "../../../../../../data/invitation";
import { Invitation } from "../../../../../../dataTypes/Invitation";
import InputWithSuffix from "../../../../../custom-ui/new-ui/InputWithSuffix";
import Input from "../../../../../custom-ui/new-ui/Input";
import { useMemo } from "react";
import { TOnSendInvite } from "../../Pages/Users_NewUI";

type TAddUserModal = {
  onDone: ({ successText, errorText }: TOnSendInvite) => void;
  employer: any;
  user: any;
};

const AddUserModal = ({ onDone, employer, user }: TAddUserModal) => {
  type FormValues = z.infer<typeof AddUserEmployerSideSchema>;

  const domain = useMemo(() => {
    const domainUrl = employer?.domain || "";
    return domainUrl.replace(/^(https?:\/\/)/, "");
  }, [employer?.domain]);

  const form = useForm<FormValues>({
    resolver: zodResolver(AddUserEmployerSideSchema),
    defaultValues: {
      fullName: "",
      email: "",
      jobTitle: "",
    },
    reValidateMode: "onBlur",
  });

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = form;

  const onSubmit = async ({ ...values }: FormValues) => {

    const invitationData: Invitation = {
      company: employer.displayName,
      employerId: employer.id,
      employerRole: "GEN",
      email: `${values.email}@${domain}`,
      name: values.fullName,
      invitedBy: user.id,
      status: "pending",
      title: values.jobTitle,
      domain: domain,
      type: "E",
    };

    try {
      await new Promise((resolve, reject) => {
        createInvitation(invitationData)
          .then(() => {
            onDone({
              successText: `Sent invitation to ${values.email}`,
            });
            resolve(true);
          })
          .catch((error) => {
            onDone({
              errorText: error?.message,
            });

            // reject();
            resolve(true);
          });
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col px-[20px] pt-[10px]">
        <div className="flex-1 flex flex-col gap-[30px]">
          <Controller
            control={control}
            name="fullName"
            render={(field) => (
              <Input
                {...field}
                display="Full Name"
                placeholder="Enter Full Name"
                error={errors?.fullName?.message}
                required={true}
              />
            )}
          />

          <Controller
            control={control}
            name="email"
            render={(field) => (
              <InputWithSuffix
                {...field}
                display="Email"
                placeholder="Enter Email"
                suffixText={`@${domain}`}
                error={errors?.email?.message}
                required={true}
              />
            )}
          />

          <Controller
            control={control}
            name="jobTitle"
            render={(field) => (
              <Input
                {...field}
                display="Job Title"
                placeholder="Enter Job Title"
                error={errors?.jobTitle?.message}
                required={true}
              />
            )}
          />
        </div>
        <div className="flex flex-row justify-center items-center gap-[15px] mt-[30px]">
          <button
            className="flex justify-center items-center w-[364px] h-[60px] py-[20px] px-[70px] rounded-[70px] bg-[#183852] text-[white]"
            type="submit"
            disabled={isSubmitting}
          >
            <p className="font-[Gilroy-Bold] font-[400] text-[20px] leading-[24.76px]">
              {isSubmitting ? "Sending Invite..." : "Send Invite"}
            </p>
          </button>
        </div>
      </div>
    </form>
  );
};

export default AddUserModal;
