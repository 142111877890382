import { useState, useEffect } from "react";
import { isNil } from "lodash";
import StatusNote from "./modals/StatusNote";
import toast from "react-hot-toast";
import ToastAlert from "../toast-alert/ToastAlert";
import useModal from "../../../hooks/useModal";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../providers/database";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoadScript } from "@react-google-maps/api";
import { useParams } from "react-router-dom";

const AutoSaveStatusSelector = ({
  status,
  collection,
  recId,
  required,
  name,
  display,
  placeholder,
  directions,
}) => {
  const location = useLocation();
  const { employerId } = useParams()
  const navigate = useNavigate();
  const containsEmployersNew = location.pathname.toLowerCase().includes("employers-new");

  const [currentStatus, setCurrentStatus] = useState("");
  const [statusID, setStatusID] = useState(status);
  const [statusCollection, setStatusCollection] = useState("");
  const [mainCollection, setMainCollection] = useState(collection);
  const [hover, setHover] = useState(false)

  useEffect(() => {
    if (mainCollection === "Employer") {
      setStatusCollection("EmployerStatus");
    }
    if (mainCollection === "Users") {
      setStatusCollection("UserStatus");
    }
    if (mainCollection === "Applications") {
      setStatusCollection("ApplicationStatus");
    }
    if (mainCollection === "Invitations") {
      setStatusCollection("InvitationStatus");
    }
    if (mainCollection === "JobDescriptions") {
      setStatusCollection("JobStatus");
    }
    if (mainCollection === "AcademyAssessments") {
      setStatusCollection("AssessmentStatus");
    }
    if (mainCollection === "AcademyAccelerators") {
      setStatusCollection("AssessmentStatus");
    }
    if (mainCollection === "Employees") {
      setStatusCollection("EmploymentStatus");
    }
  }, [mainCollection, statusCollection]);

  const { isShowing: isShowingNote, toggle: toggleNote } = useModal();

  const handleDone = (message, newStatus) => {
    toggleNote();
    if (message !== undefined) {
      toast.success(message);
    }
    if (newStatus !== undefined) {
      setStatusID(newStatus);
    }
  };

  useEffect(() => {
    setStatusID(status);
  }, [status]);

  useEffect(() => {
    if (
      !isNil(statusCollection) &&
      statusCollection !== "" &&
      !isNil(statusID) &&
      statusID !== ""
    ) {
      const activeStatusCollection = doc(db, statusCollection, statusID);
      async function fetchOptions(docRef, myStatusCollection) {
        await getDoc(docRef)
          .then(async (data) => {
            setCurrentStatus(data.data().label);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      fetchOptions(activeStatusCollection, statusCollection);
    }
  }, [statusID, statusCollection]);

  return (
    <div id={name} className="relative w-full mb-4">
      <label className="text-instant-teams-teal-Main text-sm  absolute  duration-300 transform -translate-y-4 scale-75 top-4 origin-[0] left-3.5 peer-focus:text-instant-teams-blue-Main peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4">
        {display} {" - Click to change status"}
      </label>
      <div id="onHover"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className="">


        <input
          type="text"
          name={name}
          placeholder={placeholder}
          value={currentStatus}
          disabled={
            containsEmployersNew ?
              (currentStatus === "Active" || currentStatus === "Dormant Account") ?
                false : true
              : false
          }
          onClick={() => {
            toggleNote();
          }}
          className="form-input shadow-md rounded-md w-full pt-3 text-xl"
          readOnly={true}
        />
        {hover &&
          containsEmployersNew &&
          !(currentStatus === "Active" || currentStatus === "Dormant Account") &&
          <>
            <div className="w-3 h-3 mt-1  ml-3 bg-white rotate-45"></div>
            <div onClick={() => navigate(`/employers/${employerId}/overview`)} className="w-full  mt-[-8px] z-50 px-2 py-1 rounded-lg bg-white   ">
              <p className="text-black underline cursor-pointer  text-[10px]">Use old employer to change status</p>
            </div>
          </>}

      </div>

      {isShowingNote && (
        <StatusNote
          onDone={handleDone}
          collection={collection}
          id={recId}
          name={name}
          display={display}
          placeholder={placeholder}
          status={status}
          required={required}
          directions={directions}
          statusCollection={
            containsEmployersNew ? "EmployerStatusNew" : statusCollection
          }
          minLength={10}
        />
      )}
      <ToastAlert />
    </div>
  );
};

export default AutoSaveStatusSelector;
