import React from 'react';

const MetricCard = ( {imgUrl,cardText, count}:{imgUrl:string,cardText:string, count:number}) => {
    return (
        <div className=' bg-white flex flex-col items-center justify-end rounded-xl p-10'>
            <img className='h-8 w-8' src={imgUrl} />
            <p className='mt-1 text-sm text-instant-teams-teal-D1'>{cardText}</p>
            <h1 className='text-instant-teams-blue-Main font-bold mt-3  text-2xl'>
               {count||0}
            </h1>
            
        </div>
    );
}

export default MetricCard;
