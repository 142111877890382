import { useState, useMemo } from "react";
import { cn } from "../../../../../../util/cn";
import SubscriptionCard from "./SubscriptionCard";
import DialogWrapper from "../../../../../custom-ui/dialog/Dialog_NewUI";
import MonthlyPlanPrePaymentModal from "./MonthlyPlanPrePaymentModal";
import SpeakWithAccountExecutiveModal from "./SpeakWithAccountExecutiveModal";

type PlansProps = {
  currentSubscription: string;
  onContinue: (subscriptionText: string) => void;
};

const PlansPage = ({ currentSubscription = "", onContinue }: PlansProps) => {
  const [selectedSubscription, setSelectedSubcription] = useState(null);
  const [isMonthlyPrePaymentModalVisible, setIsMonthlyPrePaymentModalVisible] =
    useState(false);
  const [
    isAnnualSpeakWithAccountExecutiveModalVisible,
    setIsAnnualAccountExecutiveLeads,
  ] = useState(false);

  const filteredSubscriptions = useMemo(() => {
    return subscriptions.filter((subscription) => {
      if (currentSubscription === "single") return true;

      return subscription.type !== currentSubscription;
    });
  }, [currentSubscription]);

  console.log(currentSubscription, "=====>currentSubscription");

  const closeMonthlyPrePaymentModal = () => {
    setIsMonthlyPrePaymentModalVisible(false);
  };

  const closeAnnualSpeakWithAccountExecutiveModal = () => {
    setIsAnnualAccountExecutiveLeads(false);
  };

  const handleContinue = () => {
    if (selectedSubscription === "single") {
      onContinue(selectedSubscription);
    } else if (selectedSubscription === "monthly") {
      setIsMonthlyPrePaymentModalVisible(true);
    } else if (selectedSubscription === "annual") {
      setIsAnnualAccountExecutiveLeads(true);
    }
  };

  const onCancelMonthlyPlanPrePayment = () => {
    setIsMonthlyPrePaymentModalVisible(false);
  };

  const onProceedMonthlyPlanPrePayment = () => {
    onContinue(selectedSubscription);
  };

  const onCancelAnnualSpeakWithAccountExecutiveModal = () => {
    setIsAnnualAccountExecutiveLeads(false);
  };

  const onSubmitAnnualSpeakWithAccountExecutiveModal = () => {
    onContinue("null");
  };

  return (
    <>
      <div className={cn("flex flex-col items-center overflow-hidden")}>
        <div
          className={cn(
            "flex flex-row gap-[30px] overflow-x-auto w-full justify-center",
          )}
        >
          {filteredSubscriptions.map((subscription: any) => {
            return (
              <SubscriptionCard
                key={subscription.type}
                type={subscription.type}
                title={subscription.title}
                subTitle={subscription.subTitle}
                price={subscription.price}
                isSelected={selectedSubscription === subscription.type}
                onClick={() => setSelectedSubcription(subscription.type)}
              />
            );
          })}
        </div>

        <button
          className={cn(
            "mt-[40px] w-[260px] h-[60px] rounded-[70px] py-[20px] px-[70px] flex flex-row justify-center items-center text-[#FFFFFF]",
            {
              "bg-[#8AA0AE]": !selectedSubscription,
              "bg-[#15415E]": selectedSubscription,
            },
          )}
          disabled={!selectedSubscription}
          onClick={handleContinue}
        >
          Continue
        </button>
      </div>

      {isMonthlyPrePaymentModalVisible && (
        <DialogWrapper
          title="&nbsp;"
          onClose={closeMonthlyPrePaymentModal}
          displayCloseIcon={true}
          displayHeaderHorizontalLine={false}
          size="max-w-[630px]"
        >
          <MonthlyPlanPrePaymentModal
            onCancel={onCancelMonthlyPlanPrePayment}
            onProceed={onProceedMonthlyPlanPrePayment}
          />
        </DialogWrapper>
      )}

      {isAnnualSpeakWithAccountExecutiveModalVisible && (
        <DialogWrapper
          title="Get In Touch"
          onClose={closeAnnualSpeakWithAccountExecutiveModal}
          displayCloseIcon={true}
          displayHeaderHorizontalLine={true}
          size="max-w-[630px]"
        >
          <SpeakWithAccountExecutiveModal
            onCancel={onCancelAnnualSpeakWithAccountExecutiveModal}
            onDone={onSubmitAnnualSpeakWithAccountExecutiveModal}
            type="request"
            source="annualSubscriptionRenewal"
          />
        </DialogWrapper>
      )}
    </>
  );
};

export default PlansPage;

const subscriptions = [
  {
    type: "single",
    title: "Single",
    subTitle: "Fuel Your Talent Pipeline with Ease",
    price: "$299",
  },
  {
    type: "monthly",
    title: "Monthly",
    subTitle: "Empower Your Hiring Strategy, Month After Month",
    price: "$599",
  },
  {
    type: "annual",
    title: "Annual",
    subTitle: "Your Gateway to Strategic Hiring Success",
    price: "",
  },
];
