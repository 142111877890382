import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAllListingsJobForEmp } from '../../../../../data/listings';
import EmployerViewMetricsChartAdmin from '../../../../../charts/EmployerViewMetricsChart_Admin';
import JobNewViewApplyMetricsChart from '../../../../../charts/jobNewViewApplyMetricsChart';

const MetricCard = ({ backgroundColor, title, value }: { backgroundColor: string, title: string, value: number }) => {
    return (
        <div className={`${backgroundColor} rounded-lg relative`}>
            <div className={`bg-[#ffffff] p-4 mt-1 shadow-lg rounded-lg relative`}>
                <p className='text-base text-instant-teams-blue-Main'>{title}</p>
                <h1 className='text-[38px] font-extrabold text-instant-teams-blue-Main'>
                    {value}
                </h1>
            </div>
        </div>
    );
};

const EmployerOverallMetricAdmin = () => {
    const { employerId } = useParams();
    const { data: jobs = [] } = useAllListingsJobForEmp(employerId || "");
    const [daysSelected, setDaysSelected] = useState(14)
    const [selectedJobs, setSelectedJobs] = useState("allJobs")
    const filterLatest30Days = (jobs: any, targetDays: number) => {
        const currentTimestamp = Math.floor(Date.now() / 1000); // Current timestamp in seconds
        const thirtyDaysAgoTimestamp = currentTimestamp - targetDays * 24 * 60 * 60; // 30 days ago in seconds

        return jobs.filter((record: any) => {
            if (record.makeLiveDate && record.makeLiveDate.seconds) {
                return record.makeLiveDate.seconds >= thirtyDaysAgoTimestamp;
            }
            return false;
        });
    };

    const filteredRecords = filterLatest30Days(jobs, daysSelected);



    const jobCounts = jobs?.reduce(
        (acc: { [key: string]: number }, job: any) => {
            if (job?.jobState) {
                acc[job.jobState] = (acc[job.jobState] || 0) + 1;
            }
            return acc;
        },
        {}
    ) || {};

    const totalLiveJobs = jobCounts["live"] || 0;
    const totalDraftJobs = jobCounts["draft"] || 0;
    const totalOnHoldJobs = jobCounts["on_hold"] || 0;
    const totalFilledJobs = jobCounts["closed_filled"] || 0;
    const totalCancelledJobs = jobCounts["closed_cancelled"] || 0;

    const accumulateLast30Days = (jobs: any) => {
        const accumulatedData: any = {};

        jobs.forEach((job: any) => {
            const date = new Date(job?.latestUpdate?.seconds * 1000).toLocaleDateString("en-US", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
            });

            if (!accumulatedData[date]) {
                accumulatedData[date] = {
                    clicksCounter: 0,
                    applyNowCounter: 0,
                    jobs: [],
                };
            }

            accumulatedData[date].clicksCounter += job.clicksCounter;
            accumulatedData[date].applyNowCounter += job.applyNowCounter;

            // Collect `id` and `jobName` if not already added for the date
            accumulatedData[date].jobs.push({
                id: job.id,
                jobName: job.jobName,
            });
        });

        const result = [];
        const today = new Date();

        for (let i = 0; i < 30; i++) {
            const currentDate = new Date(today);
            currentDate.setDate(today.getDate() - i);

            const dateStr = currentDate.toLocaleDateString("en-US", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
            });

            result.push({
                date: dateStr,
                clicksCounter: accumulatedData[dateStr]?.clicksCounter || 0,
                applyNowCounter: accumulatedData[dateStr]?.applyNowCounter || 0,
                jobs: accumulatedData[dateStr]?.jobs || [],
            });
        }

        return result;
    };


    const result = accumulateLast30Days(jobs.filter((job: any) => job.jobState === "live"));
    console.log()


    const metrics = [
        { backgroundColor: 'bg-[#82d861]', title: 'Live', value: totalLiveJobs },
        { backgroundColor: 'bg-instant-teams-blue-D2', title: 'Draft', value: totalDraftJobs },
        { backgroundColor: 'bg-instant-teams-teal-D1', title: 'On Hold', value: totalOnHoldJobs },
        { backgroundColor: 'bg-instant-teams-teal-L1', title: 'Closed', value: totalCancelledJobs },
        { backgroundColor: 'bg-instant-teams-teal-L4', title: 'Filled', value: totalFilledJobs },
    ];
    console.log(selectedJobs)
    return (
        <div className='w-full space-y-5 h-full'>
            <div className='grid grid-cols-5 mr-4 space-x-3'>
                {metrics.map((metric, index) => (
                    <MetricCard
                        key={index}
                        backgroundColor={metric.backgroundColor}
                        title={metric.title}
                        value={metric.value}
                    />
                ))}
            </div>
            <div className=' flex justify-end mr-4 mt-'>
                <div className='space-x-3 '>
                    <select
                        style={{ border: "1px #14343e solid" }}
                        value={selectedJobs}
                        className="w-[170px] bg-white text-[#14343e] border-2 border-black py-2"
                        onChange={(e) => setSelectedJobs(e.target.value)}
                    >
                        <option className="py-2" value={"allJobs"}>All Live Jobs</option>
                        {
                            result.slice(0,daysSelected)?.map((data: any) =>
                                data?.jobs?.map((job: any, index: number) => (
                                    <option key={`${data.date}-${index}`} value={job.id}>
                                        {job.jobName}
                                    </option>
                                ))
                            )
                        }
                    </select>
                    <select
                        style={{ border: "1px #14343e solid" }}
                        value={daysSelected}
                        className="w-[190px] bg-white text-[#14343e]  py-2"
                        onChange={(e) => { setDaysSelected(e.target.value) }}
                    >
                        <option className="py-2" value={3}>Last 3 Days</option>
                        <option className="py-2" value={7}>Last 7 Days</option>
                        <option className="py-2" value={14}>Last 14 Days</option>
                        <option className="py-2" value={30}>Last 30 Days</option>
                    </select>
                </div>

            </div>
            <div>
                {selectedJobs === "allJobs" ? <EmployerViewMetricsChartAdmin data={result} daysSelected={daysSelected} /> : <JobNewViewApplyMetricsChart fromAdmin={true} daysSelectedfromAdmin={daysSelected} jobId={selectedJobs} />}
            </div>
        </div>
    );
};

export default EmployerOverallMetricAdmin;
