import { JobDetailsNewCollection } from './../dataTypes/JobDetailsNewCollection';
import {
  useQuery,
  hashQueryKey,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import {
  runTransaction,
  onSnapshot,
  doc,
  collection,
  collectionGroup,
  getCountFromServer,
  query,
  where,
  orderBy,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  addDoc,
  deleteDoc,
  limit,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";
import { db, client, createQuery, format } from "../providers/database";
import { JobDescription } from "../dataTypes/JobDescription";
import { File } from "../dataTypes/Utilities";
import {
  collectionNames,
  subCollectionNames,
} from "./dictionary/collectionNames";
import { list } from "firebase/storage";

export function useAllListings(trigger: any) {
  return useQuery(
    ["Listings"],
    createQuery(() =>
      query(collection(db, collectionNames.listings), orderBy("title", "asc")),
    ),
    { enabled: !!trigger },
  );
}

export function useListing(id: string) {
  return useQuery(
    ["employer", { id }],
    createQuery(() => doc(db, collectionNames.listings, id)),
    { enabled: !!id },
  );
}

export function useAllListingsToInviteJob() {
  return useQuery(
    ["Listings"],
    async () => {
      const q = query(
        collection(db, collectionNames.listings),
        orderBy("title", "asc"),
      );
      const querySnapshot = await getDocs(q);
      const listings = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      return listings;
    },
    {
      staleTime: 60000, // Example: Cache for 1 minute
    },
  );
}

export function useAllListingsJobForNewEmp(companyId:string) {
  return useQuery(
    ["JobDetailsNewCollection", companyId],
    async () => {
      // Create the base query
      let q = query(collection(db, "JobDetailsNewCollection"));
      if (companyId) {
        q = query(
          collection(db, "JobDetailsNewCollection"),
          where("companyId", "==", companyId),
         
        );
      }

      const querySnapshot = await getDocs(q);
      const listings = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      return listings;
    },
    {
      enabled: !!companyId, // Ensures the query runs only if companyId is provided
    }
  );
}

export function useJobByIdForNewEmp(jobId: string | undefined) {
  return useQuery(
    ["JobDetailsNewCollection", jobId],
    async () => {
      if (!jobId) {
        throw new Error("Job ID is required to fetch the job details.");
      }

      // Get the document by its ID
      const jobDoc = doc(db, "JobDetailsNewCollection", jobId);
      const docSnapshot = await getDoc(jobDoc);

      if (!docSnapshot.exists()) {
        throw new Error("Job not found.");
      }

      return {
        id: docSnapshot.id,
        ...docSnapshot.data(),
      };
    },
    {
      enabled: !!jobId, // Only run the query if jobId is defined
    }
  );
}

export function useAllListingsJobForNew(companyId: any) {
  return useQuery(
    ["JobDetailsNewCollection", companyId],
    async () => {
      // Create the base query
      let q = query(
        collection(db, "JobDetailsNewCollection"),
        where("jobState", "==", "live"),
      );

      // Add companyId filter if provided
      if (companyId) {
        q = query(
          collection(db, "JobDetailsNewCollection"),
          where("jobState", "==", "live"),
          where("companyId", "==", companyId),
        );
      }

      const querySnapshot = await getDocs(q);
      const listings = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      return listings;
    },
    {
      enabled: true, // Ensure the query runs even when companyId is null or undefined
    },
  );
}

export function useAllListingsJobForEmp(companyId: any) {
  return useQuery(
    ["JobDetailsNewCollection", companyId],
    async () => {
      // Create the base query
      let q = query(
        collection(db, "JobDetailsNewCollection"),
        where("jobState", "==", "live"),
      );

      // Add companyId filter if provided
      if (companyId) {
        q = query(
          collection(db, "JobDetailsNewCollection"),
          where("companyId", "==", companyId),
        );
      }

      const querySnapshot = await getDocs(q);
      const listings = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      return listings;
    },
    {
      enabled: true, // Ensure the query runs even when companyId is null or undefined
    },
  );
}

export function useAllListingsJobForNewByCompanyId(
  companyId: any,
  companyName: string,
) {
  return useQuery(
    ["JobDetailsNewCollection", companyId, companyName],
    async () => {
      // First query: fetch jobs with companyId and jobState as 'live'
      const baseQuery = query(
        collection(db, "JobDetailsNewCollection"),
        where("jobState", "==", "live"),
        where("companyId", "==", companyId),
      );
      const baseSnapshot = await getDocs(baseQuery);
      const baseListings = baseSnapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter((listing: any) => listing.solutionType !== "bpo");

      let additionalListings: any = [];
      // Second query: fetch jobs with solutionType as 'bpo' if the companyName is 'Instantteams'
      if (companyName.toLowerCase().startsWith("instant")) {
        const additionalQuery = query(
          collection(db, "JobDetailsNewCollection"),
          where("jobState", "==", "live"),
          where("solutionType", "==", "bpo"),
        );
        const additionalSnapshot = await getDocs(additionalQuery);
        additionalListings = additionalSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
      }

      // Combine results from both queries
      return [...baseListings, ...additionalListings];
    },
    {
      enabled: !!companyId,
    },
  );
}

export function useListingJobByDocId(docId: any) {
  return useQuery(
    ["JobDetailsNewCollection", docId],
    async () => {
      // Fetch the document by its ID
      const docRef = doc(db, "JobDetailsNewCollection", docId);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        return {
          id: docSnapshot.id,
          ...docSnapshot.data(),
        };
      } else {
        throw new Error("Document not found");
      }
    },
    {
      enabled: !!docId, // Ensure the query runs only when docId is provided
    },
  );
}
