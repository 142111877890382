import { useState, useEffect } from "react";

type MonthlySubscriptionPreTextProps = {
  onContinue: () => void;
};

const MonthlySubscriptionPreText = ({
  onContinue,
}: MonthlySubscriptionPreTextProps) => {
  return (
    <>
      <div className="max-w-2xl mx-auto p-6 overflow-y-scroll no-scrollbar bg-white justify-center flex flex-col">
        {/* Radio Buttons for Billing Options */}
        <div className="relative mb-7">
          <label className="absolute -top-4 mb-4 text-xl text-[#15415E] bg-white px-1 transition-all duration-200">
            Monthly Plan Selected
          </label>
        </div>

        <div className="p-8 rounded shadow-md text-center w-full  self-center">
          <p className="mt-4 text-gray-600">Some text for now</p>
        </div>

        <div className="flex justify-center mt-[30px]">
          <button
            onClick={() => onContinue()}
            className="py-2 px-8 text-white font-medium rounded-2xl focus:outline-none focus:ring-2 bg-[#15415E]"
            style={{ width: "200px" }}
          >
            Continue
          </button>
        </div>
      </div>
    </>
  );
};

export default MonthlySubscriptionPreText;
