import { useState, useMemo } from "react";
import { cn } from "../../../../util/cn";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useEffect } from "react";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import { employerBillingSchema } from "../registration/Schema/registrationSchema";
import { EmployerNew } from "../../../../dataTypes/Employer";
import {
  getCurrentPlanText,
  updateEmployerNew,
} from "../../../../data/employer";
import toast from "react-hot-toast";
import BulletTextWrapper from "../../details/New_Ui/Component/CurrentPlan/BulletTextWrapper";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

type PaymentPageProps = {
  onContinue: Function;
  currentPlan: string;
  employerInfo: any;
};

const PaymentPage = ({
  onContinue,
  currentPlan,
  employerInfo,
}: PaymentPageProps) => {
  // console.log(
  //   JSON.stringify(currentPlan, null, 2),
  //   "======>currentPlan on payment page",
  // );
  // console.log(
  //   JSON.stringify(employerInfo, null, 2),
  //   "======>employerInfo on payment page",
  // );

  // useEffect(() => {
  //   currentPlan = localStorage.getItem(currentPlan) || "single";
  //   employerInfo = localStorage.getItem(employerInfo) || {};
  // }, []);

  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/payment-return`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "accordion",
  };

  const currentPlanText = useMemo(
    () => getCurrentPlanText(currentPlan),
    [currentPlan],
  );

  return (
    <div className="max-w-2xl w-full mx-auto overflow-y-scroll no-scrollbar bg-white">
      {/* Radio Buttons for Billing Options */}
      <div className="relative mb-7">
        <label className="relative font-[Gilroy-Medium] font-[400] text-[24px] leading-[29.11px] text-[#15415E] bg-white px-1 transition-all duration-200">
          Subscription Information
        </label>

        <div className="w-full mt-[20px] w-auto h-[236px] rounded-[15px] bg-gradient-to-r from-[#0B619A] to-[#4DAAE8] flex-1 flex flex-col text-[#FFFFFF] p-[20px]">
          <div className="flex flex-row justify-between items-center">
            {currentPlan === "single" && (
              <p className="font-[Gilroy-Bold] font-[400] text-[20px] leading-[24.76px] tracking-wider">
                <span className="font-[Gilroy-Medium]  text-cetner  font-[400] text-[20px] leading-[24.26px] ">
                  Fuel Your Talent Pipeline with Ease
                </span>
              </p>
            )}

            {currentPlan === "monthly" && (
              <p className="font-[Gilroy-Bold] font-[400] text-[20px] leading-[24.76px] tracking-wider">
                <span className="font-[Gilroy-Medium]  text-cetner  font-[400] text-[20px] leading-[24.26px] ">
                  Empower Your Hiring Strategy, Month After Month
                </span>{" "}
              </p>
            )}

            {currentPlan === "annual" && (
              <p className="font-[Gilroy-Bold] font-[400] text-[20px] leading-[24.76px] tracking-wider">
                <span className="font-[Gilroy-Medium]  text-cetner  font-[400] text-[20px] leading-[24.26px] ">
                  Your Gateway to Strategic Hiring Success
                </span>
              </p>
            )}
          </div>

          <div className="flex flex-col flex-1 gap-[10px] font-[Gilroy-Regular] font-[400] text-[12px] leading-[14.4px] tracking-wider mt-[20px]">
            {currentPlan === "single" && (
              <>
                <BulletTextWrapper>
                  Includes one 30-day job post
                </BulletTextWrapper>
                <BulletTextWrapper>
                  Utilize anytime within a year
                </BulletTextWrapper>
                <BulletTextWrapper>
                  Features employer profile and logo
                </BulletTextWrapper>
                <BulletTextWrapper>
                  Funnels candidates directly to your ATS
                </BulletTextWrapper>
              </>
            )}

            {currentPlan === "monthly" && (
              <>
                <BulletTextWrapper>
                  Includes three (3) 30-day job post credits each month

                </BulletTextWrapper>
                <BulletTextWrapper>
                  Credits refresh monthly to maintain active participation
                </BulletTextWrapper>
                <BulletTextWrapper>
                  Features employer profile and logo
                </BulletTextWrapper>
                <BulletTextWrapper>
                  Funnels candidates directly to your ATS
                </BulletTextWrapper>
                <BulletTextWrapper>
                  Automatic renewal and billing, cancel anytime
                </BulletTextWrapper>
              </>
            )}

            {currentPlan === "annual" && (
              <>
                <BulletTextWrapper>
                  Customizable Packages
                </BulletTextWrapper>
                <BulletTextWrapper>Seamless Renewals</BulletTextWrapper>
                <BulletTextWrapper>
                  Expert Partnership & Sales Support
                </BulletTextWrapper>
              </>
            )}
          </div>

          {/* <button className="underline font-[Gilroy-Bold] font-[400] text-[16px] leading-[19.81px] mb-[5px] tracking-wider">
            See All Benefits
          </button> */}
        </div>
      </div>

      <p className="font-[Gilroy-Medium] font-[400] text-[24px] leading-[29.11px] text-[#15415E] mt-[26px]">
        Payment Details
      </p>

      <form id="payment-form" onSubmit={handleSubmit} className="mt-[20px]">
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <div className="flex flex-col justify-center items-center">
          <button
            disabled={isLoading || !stripe || !elements}
            id="submit"
            className="w-[260px] h-[60px] py-[20px] px-[70px] bg-[#15415E] rounded-[70px] mt-[30px]"
          >
            <span
              id="button-text"
              className="font-[Gilroy-SemiBold] font-[400] text-[16px] leading-[19.6px] text-[#FFFFFF] gap-[10px]"
            >
              {isLoading && <div className="spinner" id="spinner"></div>}
              Pay Now
            </span>
          </button>
          {/* Show any error or success messages */}
          {message && (
            <div id="payment-message" className="text-[red] mt-[5px]">
              {message}
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default PaymentPage;
