import { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
// import { createStripePaymentIntent } from "../../../../callable-cloud-functions/cloudFunctions";
import { createStripeClientSecret } from "../../../../callable-cloud-functions/cloudFunctions";
import { useNavigate } from "react-router-dom";
import PaymentPage from "../Components/PaymentPage";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(
  "pk_test_51PxsDGELIBAvshmEguDLDTRKLzRxS0ehcciCmJ9Te5zJ6gQJJyv7MM84VOrLqnJ6DH0B5s5DtHJSE5EgsPLY4FTJ00gMXxCpqB",
);

const appearance = {
  theme: "stripe",
};
// Enable the skeleton loader UI for optimal loading.
const loader = "auto";

type ElementsProviderProps = {
  currentPlan: any;
  employerInfo: any;
  priceId: string;
};

const ElementsProvider = ({
  currentPlan,
  employerInfo,
  priceId,
}: ElementsProviderProps) => {
  // useEffect(() => {
  //   employerInfo = localStorage.getItem(employerInfo) || {};
  // }, []);
  const navigate = useNavigate();

  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    let isMounted = true;

    async function fetchData() {
      try {
        console.log(currentPlan, "==========>currentPlan inside fetchData");

        const result = await createStripeClientSecret({
          employerId: employerInfo?.employerId,
          newSubscriptionPlan: currentPlan,
          // amount: 299, // Convert to cents
          // currency: "usd",
        });

        console.log(result, "===========>result");

        // Cast the response to the expected type
        const data = result.data as any;

        console.log(data, "==========>data");

        if (isMounted && data) {
          setClientSecret(data?.clientSecret);
        }
      } catch (error) {
        console.log(error);
      } finally {
        // setIsLoading(false);
      }
    }
    fetchData();

    return () => {
      isMounted = false;
    };
  }, [employerInfo?.employerId]);

  //   useEffect(() => {
  //     let isMounted = true;

  //     fetch("/create-subscription", {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       // body: JSON.stringify({ items: [{ id: "xl-tshirt", amount: 2500 }] }),
  //       body: JSON.stringify({
  //         // priceId: priceId,
  //         // customerId: customerId,
  //       }),
  //       // body: JSON.stringify({ items: [{ id: "xl-tshirt", amount: 2500 }] }),
  //     })
  //       .then((res) => res.json())
  //       .then((data) => {
  //         if (isMounted) {
  //           console.log(data, "=====>data");
  //           setClientSecret(data.clientSecret);
  //           //   setDpmCheckerLink(data.dpmCheckerLink);
  //         }
  //       });

  //     return () => {
  //       isMounted = false;
  //     };
  //   }, []);

  const handlePayment = (data: any) => {
    console.log(data, "======>data inside handlePayment");

    navigate("/home");
    // setRenderView(3)
    // onDone(data);
  };

  return (
    <>
      {clientSecret ? (
        <Elements
          options={{ clientSecret, appearance, loader }}
          stripe={stripePromise}
        >
          {/* {navigate("/home")} */}
          <div className="w-full flex flex-row justify-start my-10">
            <PaymentPage
              onContinue={handlePayment}
              currentPlan={currentPlan}
              employerInfo={employerInfo}
            />
          </div>
        </Elements>
      ) : (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
          <div className="bg-white p-8 rounded shadow-md text-center max-w-md">
            <h1 className="text-2xl font-bold text-gray-800">
              Redirecting you to payments page...
            </h1>
            <p className="mt-4 text-gray-600">
              Please do not refresh or close this page.
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default ElementsProvider;
