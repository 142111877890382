import CompanyDetailsCard from "../Component/CompanyProfile/CompanyDetailsCard";
import CompanySocialAccountsCard from "../Component/CompanyProfile/CompanySocialAccountsCard";
import CompanyInformationCard from "../Component/CompanyProfile/CompanyInformationCard";
import Meta from "../../../../custom-ui/meta/Meta";

type Props = {};

const CompanyProfile_NewUIAdmin = (props: Props) => {
    return (
        <div className="justify-center items-center" >
            <Meta title="Company Profile" />
            <div className=" flex flex-col  justify-center items-center">
                <p className="text-[#15415E] font-[Gilroy-Bold] font-[400] text-[32px] leading-[39.62px]">
                    Company Profile
                </p>
                <div className="flex flex-col w-[60%]  mt-[55px] gap-[30px]">
                    <CompanyDetailsCard />
                    <CompanySocialAccountsCard />
                    <CompanyInformationCard />
                </div>
            </div>
        </div>
    );
};

export default CompanyProfile_NewUIAdmin;
