import React from 'react';
import { convertTimestamp_subAdmin } from '../../util';
import DownloadIconWithLabel from '../../../../../dashboard/AdminMetricDashboard/DownLoadIcon';

const PaymentInformationEmpProfile = ({ subcriptionData }: any) => {

    function formatToUSD(amount: number) {
        return `$${amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
    }


    return (
        <div>
            <h1 className='text-instant-teams-teal-Main text-lg font-extrabold'> Payment Information</h1>
            <div className='w-full mt-5 grid grid-cols-4'>
                <div className='col-span-2 flex items-center'>
                    <h1 className='text-instant-teams-teal-Main text-lg  '> Amount </h1>
                </div>
                <div className='col-span-1 flex items-center'>
                    <h1 className='text-instant-teams-teal-Main text-lg '> Payment Date</h1>
                </div>
                {/* <div className='col-span-1 flex justify-center items-center'>
                    <h1 className='text-instant-teams-teal-Main text-lg '> Action </h1>
                </div> */}
            </div>
            <hr className='w-full bg-gray-100 text-gray-50 my-5 ' />
            {subcriptionData?.paymentInformation?.map((data: any,index:number) => {
                  const { date, mAndY } = convertTimestamp_subAdmin(data?.createdAt?._seconds) || {};
              return  (
                 <div key={index+1}>
                 <div className='w-full mt-5  grid grid-cols-4'>
                     <div className='col-span-2 flex items-center'>
                         <h1 className='text-instant-teams-teal-Main text-lg '> {formatToUSD(data?.amount)} </h1>
                     </div>
                     <div className='col-span-1 flex items-center'>
                              <h1 className='text-instant-teams-teal-Main text-lg '>{date+"  "+ mAndY}</h1>
                     </div>
                     {/* <div className='col-span-1 flex justify-center items-center'>
                      <DownloadIconWithLabel tooltipText={""}/>
                     </div> */}
 
                 </div>
                 <hr className='w-full bg-gray-100 text-gray-50 my-5 ' />
             </div>

            )})}

           


        </div>
    );
}

export default PaymentInformationEmpProfile;
