import React from 'react';
import { convertTimestamp_subAdmin } from '../../util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import DialogWrapper from '../../../../../custom-ui/dialog/Dialog';
import useModal from '../../../../../../hooks/useModal';
import AddMoreCredtis from './AddMoreCredits';
const CreditsInformationEmpProfile = ({ subcriptionData, empId }: any) => {

    const { isShowing: showAddMoreCreditsModal, toggle: toggleshowAddMoreCreditsModal } = useModal();
    return (
        <>

            <div className='w-full h-full'>
                <div className='w-full flex items-center justify-between'>
                    <h1 className='text-instant-teams-teal-Main text-xl font-extrabold'> Credit Informations </h1>
                    <button
                        onClick={() => toggleshowAddMoreCreditsModal()}
                        className='bg-instant-teams-blue-Main  text-white px-12 py-1'>
                        Add More Credits
                    </button>
                </div>

                <div className='w-full mt-5 grid grid-cols-4'>
                    <div className='col-span-2 flex items-center'>
                        <h1 className='text-instant-teams-teal-Main text-lg  '> Credits </h1>
                    </div>
                    <div className='col-span-1 flex items-center'>
                        <h1 className='text-instant-teams-teal-Main text-lg '> Credits Added</h1>
                    </div>
                    <div className='col-span-1 flex justify-center items-center'>
                        <h1 className='text-instant-teams-teal-Main text-lg '> Credits Expire </h1>
                    </div>
                </div>
                <hr className='w-full bg-gray-100 text-gray-50 my-5 ' />
                {subcriptionData?.creditExpiryEntries.map((data: any,index:number) => {
                    const { date, mAndY } = convertTimestamp_subAdmin(data?.createdAt) || {};
                    const { date: dateExp, mAndY: mAndYExp } = convertTimestamp_subAdmin(data?.expireAt) || {};
                    return (
                        <div key={index+1}>
                            <div className='w-full mt-5  grid grid-cols-4'>
                                <div className='col-span-2 flex items-center'>
                                    <h1 className='text-instant-teams-teal-Main text-lg '>{data?.credits} </h1>
                                </div>
                                <div className='col-span-1 flex items-center'>
                                    <h1 className='text-instant-teams-teal-Main text-lg '>{date + "  " + mAndY}</h1>
                                </div>
                                <div className='col-span-1 flex justify-center items-center'>
                                    <h1 className='text-instant-teams-teal-Main text-lg '>{dateExp + "  " + mAndYExp}</h1>
                                </div>

                            </div>
                            <hr className='w-full bg-gray-100 text-gray-50 my-5 ' />
                        </div>

                    )
                })}
            </div>
            {
                showAddMoreCreditsModal && (
                    <div className="fixed w-full inset-0 xs:p-4 md:p-4 flex items-center justify-center z-50">
                    <div className="fixed inset-0 bg-black opacity-50" onClick={toggleshowAddMoreCreditsModal}></div>
                    <div
                        className={`relative transform  transition-transform  duration-300 xs:w-full md:w-full ease-in-out bg-white rounded-2xl w-[22vw] shadow-lg p-8`}

                    >
                        <>
                            <div className="w-full xs:mt-2 md:mt-2  flex justify-between items-start" >
                                <h1 className='text-instant-teams-blue-Main text-xl text-center font-semibold'>Add Credits</h1>
                                <div
                                    className="flex  justify-center items-center w-7 h-7 bg-gray-200 rounded-full cursor-pointer"
                                    onClick={toggleshowAddMoreCreditsModal}
                                >
                                    <FontAwesomeIcon icon={faTimes} color="#565656" />
                                </div>
                            </div>
                            <AddMoreCredtis empId={ empId} toggleshowAddMoreCreditsModal={toggleshowAddMoreCreditsModal} />
                        </>
                        </div>
                        </div>
                )
            }
        </>
    );
}

export default CreditsInformationEmpProfile;
