import { useEmployer } from "../../../../../data/employer";
import { useAuth } from "../../../../../providers/auth";
import Meta from "../../../../custom-ui/meta/Meta";
import BillingContactCard from "../Component/BillingInformation/BillingContactCard";
import SavedCardComponent from "../Component/BillingInformation/SavedCardComponent";

type Props = {};

const BillingInformation_NewUI = (props: Props) => {
  const { user } = useAuth();
  const employerId = user?.claims?.employerId;
  const { data: employer } = useEmployer(employerId ?? "");

  return (
    <>
      <Meta title="Billing Information" />
      <div className="flex flex-col">
        <p className="text-[#15415E] font-[Gilroy-Bold] font-[400] text-[32px] leading-[39.62px]">
          Billing Information
        </p>
        <div className="flex flex-row lg:flex-col md:flex-col mt-[55px] gap-[30px]">
          <BillingContactCard />
          {employer.currentPlan === "monthly" ? <SavedCardComponent /> : <></>}
        </div>
      </div>
    </>
  );
};

export default BillingInformation_NewUI;
