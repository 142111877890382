import { useState, useEffect } from "react";
import CardWrapper from "./CardWrapper";
import { useEmployer } from "../../../../../../data/employer";
import ToastAlert from "../../../../../custom-ui/toast-alert/ToastAlert";
import { useAuth } from "../../../../../../providers/auth";
import DialogWrapper from "../../../../../custom-ui/dialog/Dialog_NewUI";
import { cn } from "../../../../../../util/cn";
import ElementsProvider from "./ElementsProvider";
import { getStripePaymentMethod } from "../../../../../../callable-cloud-functions/cloudFunctions";
import CreditCardComponent from "./CreditCardComponent";

type SavedCardComponentProps = {};

const SavedCardComponent = ({}: SavedCardComponentProps) => {
  const { user } = useAuth();
  const employerId = user?.claims?.employerId;
  const { data: employer } = useEmployer(employerId ?? "");

  // @ts-ignore
  const [isInitialized, setIsInitialized] = useState(false);
  const [isModalUpdateCard, setIsModalUpdateCard] = useState(false);
  const [cardDetails, setCardDetails] = useState(null);

  const closeModal = () => {
    setIsModalUpdateCard(false);
  };

  const handleUpdateCard = () => {
    setIsModalUpdateCard(true);
  };

  const fetchStripePaymentMethod = async () => {
    try {
      const result = await getStripePaymentMethod({
        employerId: employerId,
        customerId: employer.customerId,
      });

      // Cast the response to the expected type
      const data = result.data as any;

      if (data) {
        setCardDetails(data?.cardDetails);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onCardSaved = () => {
    setIsModalUpdateCard(false);

    if (employer) {
      fetchStripePaymentMethod();
    }
  };

  useEffect(() => {
    if (employer && !isInitialized) {
      setIsInitialized(true);

      fetchStripePaymentMethod();
    }
  }, [employer, isInitialized]);

  return (
    <>
      <CardWrapper className="h-fit w-fit">
        <div className="flex flex-col flex-wrap">
          <div className="flex flex-row justify-between items-center">
            <p className="font-[Gilroy-Bold] font-[400] text-[20px] leading-[25px] text-[#15415E] uppercase tracking-wide">
              Saved Card
            </p>

            <div className="flex flex-row gap-[10px]">
              <button
                className={cn(
                  "bg-[#15415E] text-[#FFFFFF] flex justify-center items-center rounded-[70px] w-[185px] h-[40px]",
                )}
                onClick={handleUpdateCard}
                disabled={false}
              >
                Update Card
              </button>
            </div>
          </div>

          <CreditCardComponent
            cardholder_name={employer?.billingContact?.name ?? ""}
            brand={cardDetails?.brand ?? ""}
            exp_month={
              cardDetails?.exp_month ? cardDetails?.exp_month.toString() : ""
            }
            exp_year={
              cardDetails?.exp_year
                ? cardDetails.exp_year.toString().substr(2, 4)
                : ""
            }
            last4={cardDetails?.last4}
          />
        </div>
      </CardWrapper>

      {isModalUpdateCard && (
        <DialogWrapper
          title="Add New Card"
          onClose={closeModal}
          displayCloseIcon={true}
          size="max-w-[730px]"
        >
          <ElementsProvider
            employerId={employer.id}
            customerId={employer.customerId}
            onDone={onCardSaved}
          />
        </DialogWrapper>
      )}
      <ToastAlert />
    </>
  );
};

export default SavedCardComponent;
