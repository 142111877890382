import { useState, useMemo } from "react";
import { cn } from "../../../../../../util/cn";
// import { zodResolver } from "@hookform/resolvers/zod";
// import { Controller, useForm } from "react-hook-form";
// import { z } from "zod";
// import Input from "../../../../../custom-ui/new-ui/Input";
// import { getAddNewCardSchema } from "../../../../../../schemas/AddNewCardSchema";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import toast from "react-hot-toast";
import ToastAlert from "../../../../../custom-ui/toast-alert/ToastAlert";

const paymentElementOptions = {
  layout: "accordion",
  fields: {
    billingDetails: "auto",
    // billingDetails: {
    //   name: "auto",
    // },
  },
  readOnly: false,
};

type TAddNewCardModalProps = {
  employerId: string;
  customerId: string;
  onDone: () => void;
};

const AddNewCardModal = ({ onDone }: TAddNewCardModalProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);

  // const [formSchema] = useState(getAddNewCardSchema());
  // type FormValues = z.infer<ReturnType<typeof getAddNewCardSchema>>;

  // const form = useForm<FormValues>({
  //   resolver: zodResolver(formSchema),
  //   defaultValues: {
  //     cardNumber: "",
  //     expiry: "",
  //     cvv: "",
  //     nameOnCard: "",
  //   },
  //   reValidateMode: "onBlur",
  // });

  // const {
  //   handleSubmit,
  //   setValue,
  //   getValues,
  //   control,
  //   reset,
  //   watch,
  //   formState: { errors, isSubmitting },
  // } = form;

  // const onSubmit = async ({ ...values }: FormValues) => {
  //   const { cardNumber, expiry, cvv, nameOnCard, ...restValues } = values;
  // };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      // confirmParams: {
      //   // Make sure to change this to your payment completion page
      //   return_url:
      //     "http://localhost:3000/payment-return?redirectTo=currentPlan",
      // },
      redirect: "if_required",
    });

    console.log(setupIntent, "===================>setupIntent");

    // if (error.type === "card_error" || error.type === "validation_error") {
    //   setMessage(error.message);
    // } else {
    //   setMessage("An unexpected error occurred.");
    // }

    if (error) {
      console.error(error.message);
      setMessage(error.message);
      // Handle the error (e.g., display a message to the user)
    } else if (setupIntent) {
      console.log("SetupIntent succeeded:", setupIntent);
      if (setupIntent.status === "succeeded") {
        toast.success("Card Details updated successfully");
        onDone();
      }
    }

    setIsLoading(false);
  };

  return (
    <>
      <div
        className={cn(
          "flex flex-col items-center overflow-hidden mt-[-20px]",
          {},
        )}
      >
        <form
          id="card-setup-form"
          onSubmit={handleSubmit}
          className="mt-[30px]"
        >
          <PaymentElement
            id="card-setup-element"
            options={paymentElementOptions}
          />

          <div className="flex flex-row justify-center items-center mt-[35px]">
            <button
              className="flex justify-center items-center h-[60px] w-[368px] py-[20px] px-[30px] rounded-[70px] bg-[#183852] text-[#FFFFFF] text-[20px] leading-[24.76px] font-[400] font-[Gilroy-Bold]"
              type="submit"
            >
              Save Card
            </button>
          </div>

          {/* Show any error or success messages */}
          {message && (
            <div id="card-setup-message" className="text-[red] mt-[5px]">
              {message}
            </div>
          )}
        </form>
      </div>
      <ToastAlert />
    </>
  );
};

export default AddNewCardModal;
