import { useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";

function EmployerViewMetricsChartAdmin({ data, daysSelected }) {
  const [chartData, setChartData] = useState([]);
  const [chartKeys, setChartKeys] = useState([]);
  const [chartTitle, setChartTitle] = useState(`Last ${daysSelected} Days`);

  useEffect(() => {
    const filteredData = data?.slice(0,daysSelected);
    const clicksCounterData = filteredData?.map((item) => item.clicksCounter).reverse();
    const applyNowCounterData = filteredData?.map((item) => item.applyNowCounter).reverse();

    const chartKeys = filteredData
      ?.map((item) => {
        const date = new Date(item.date);
        return `${date.getMonth() + 1}/${date.getDate()}`;
      })
      .reverse();

    setChartData({
      clicksCounter: clicksCounterData,
      applyNowCounter: applyNowCounterData,
    });
    setChartKeys(chartKeys);
  }, [data, daysSelected]);

  const options = {
    chart: {
      type: "line",
      height: "100%",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: chartKeys,
      labels: {
        rotate:90,
        style: {
          fontSize: "12px",
          colors: "#333",
        },
      },
    },
    tooltip: {
      enabled: true,
    },
    yaxis: {
      title: {
        text: "Counts",
      },
      min: 0,
    },
    stroke: {
      curve: "straight",
      width: 2,
    },
    markers: {
      size: 5,
      hover: {
        size: 7,
      },
    },
    colors: ["#187b96", "#183853"],
    dataLabels: {
      enabled: false,
    },
    series: [
      {
        name: "Clicks Counter",
        type: "bar",
        data: chartData.clicksCounter,
        color: "#187b96",
      },
      {
        name: "Apply Now Counter",
        type: "bar",
        data: chartData.applyNowCounter,
        color: "#183853",
      },
    ],
  };

  return (
    <div className="bg-white shadow-lg rounded-lg p-4 pb-8">
      
      <div className="w-full text-[#183853] pb-3 h-80">
        <div className="flex items-center ">
          <h1 className="text-xl text-[#1E596D] font-bold">Metrics</h1>
          <p className="text-sm ml-2 items-center mt-2 text-[#3b7f96]">
            {`(Last ${daysSelected} Days)`}
          </p>
        </div>
        <ApexCharts options={options} series={options.series} type="line" height="100%" />
      </div>
    </div>
  );
}

export default EmployerViewMetricsChartAdmin;
