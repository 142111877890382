import React, { useState, useEffect, useMemo } from "react";
import AuthCode from "react-auth-code-input";
import "react-phone-number-input/style.css";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import {
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  multiFactor,
} from "firebase/auth";
import { useForm } from "react-hook-form";
import { useAuth } from "../../../providers/auth";
import { friendlyFirebaseError } from "../../../providers/firebase-errors/friendlyFirebaseError";
import { errorLogger } from "../../../providers/clientLogger";
import toast from "react-hot-toast";
import ToastAlert from "../../../components/custom-ui/toast-alert/ToastAlert";

function PasswordSettings({
  closeModal,
  callback
}: {
  closeModal: Function;
  callback: Function;


}) {
  const auth = useAuth();



  const [isCodeComplete, setIsCodeComplete] = useState(false);
  const [verificationCode, setVerificationCode] = useState();

  const { getMultifactorSession, makePhoneAuthProvider, makeRecaptcha, user } = auth;
  const phoneAuthProvider = makePhoneAuthProvider();
  const [timeLeft, setTimeLeft] = useState(0);

  const [pending, setPending] = useState(false);
  const { handleSubmit, register, errors, setValue, getValues, control } = useForm();

  useEffect(() => {
    if (user?.phone) {
      setValue("mobilePhone", user.phone);
    }
  }, [user?.phone, setValue]);


  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const [multifactorSession, setMultifactorSession] = useState(null);
  const [verificationId, setVerificationId] = useState(undefined);
  const [phoneNumber, setPhoneNumber] = useState(undefined);

  const recaptchaConfig = useMemo(
    () => ({
      size: "invisible",
      callback: function (response: any) { },
    }),
    [],
  );

  useEffect(() => {
    if (!recaptchaVerifier) {
      const verifier = makeRecaptcha("multifactor-enrollment", recaptchaConfig);
      setRecaptchaVerifier(verifier);
    }
  }, [recaptchaConfig, makeRecaptcha, recaptchaVerifier]);

  useEffect(() => {
    const getSession = async () => {
      try {
        const session = await getMultifactorSession(user.auth.currentUser);
        setMultifactorSession(session);
      } catch (error) {
        console.error("Error getting multifactor session:", error);
      }
    };
    if (user.auth.currentUser) {
      getSession();
    }
  }, [user.auth.currentUser, getMultifactorSession]);

  const getVerificationCode = ({ mobilePhone }: { mobilePhone: string }) => {
    setPending(true);
    setPhoneNumber(mobilePhone);
    const phoneInfoOptions = {
      phoneNumber: mobilePhone,
      session: multifactorSession,
    };

    phoneAuthProvider
      .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
      .then((verificationId: string) => {
        setVerificationId(verificationId);
        //startTimer(); // Start the 60-second timer after sending the code
        toast.success(`Verification code sent to phone number`);

      })
      .catch((error: any) => {

        errorLogger(error.toString(), {
          component: "MultifactorEnrollment getVerificationCode()",
          flow: "MultiFactor Enrollment Flow",
          userId: user.id,
        });
        toast.error(`${friendlyFirebaseError(error)}`);
        return;
      })
      .finally(() => setPending(false));
  };



  const getVerificationCodeN = ({ mobilePhone }: { mobilePhone: string }) => {
    setPending(true);
    setPhoneNumber(mobilePhone);
    const phoneInfoOptions = {
      phoneNumber: mobilePhone,
      session: multifactorSession,
    };

    phoneAuthProvider
      .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
      .then((verificationId: string) => {
        setVerificationId(verificationId);
        startTimer(); // Start the 60-second timer after sending the code
        toast.success(`Verification code sent to phone number`);
      })
      .catch((error: any) => {

        errorLogger(error.toString(), {
          component: "MultifactorEnrollment getVerificationCode()",
          flow: "MultiFactor Enrollment Flow",
          userId: user.id,
        });
        toast.error(`${friendlyFirebaseError(error)}`);
        return;
      })
      .finally(() => setPending(false));
  };



  const sendVerificationCode = ({
    verificationCode,
  }: {
    verificationCode: string;
  }) => {
    setPending(true);
    const credential = PhoneAuthProvider.credential(
      verificationId,
      verificationCode,
    );
    const multiFactorAssertion =
      PhoneMultiFactorGenerator.assertion(credential);
    multiFactor(user.auth.currentUser)
      .enroll(multiFactorAssertion, "mobile phone number")
      .then((response) => {

        callback()
        closeModal();
      })
      .catch((error) => {

        toast.error(`${friendlyFirebaseError(error)}`);

        errorLogger(error.toString(), {
          component: "MobilePhoneEntryForm",
          flow: "2FA Enrollment Flow",
          userId: user.id,
        });
      })
      .finally(() => {
        setPending(false);
      });
  };



  const startTimer = () => {
    setTimeLeft(60); // Start the countdown at 60 seconds
  };

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [timeLeft]);

  const reRequestCode = (phoneNumber: string) => {
    if (timeLeft === 0) {
      getVerificationCodeN({ mobilePhone: phoneNumber });
    }
  };

  const handleCodeChange = (code: any) => {
    setValue("verificationCode", code);
    setVerificationCode(code);
    setIsCodeComplete(code.length === 6); // Assuming the code is 6 digits long
  };



  return (

    <>

      <div className="flex mb-10">

        <p className="border-b w-full font-bold py-2 text-xl text-[#15415E]">Change Phone Number</p>
      </div>



      <>
        <div className="flex w-full items-center md-10">
          <div id="multifactor-enrollment"></div>
          {!verificationId && (
            <form onSubmit={handleSubmit(getVerificationCode)}>

              <div className="relative mb-6 w-full">
               
                <PhoneInputWithCountry
                  className="peer mt-1 block px-3 py-2 inp sm:text-sm border-none"
                  style={{ boxShadow: 'none' }}
                  name="mobilePhone"
                  international
                  defaultCountry="US"
                  countryCallingCodeEditable={false}
                  control={control}
                  rules={{ required: true }}
                  onChange={(code: any) => setValue("mobilePhone", code)}
                />

                <label
                  htmlFor="phoneNumberV"
                  className="absolute left-3 -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
                >
                  Phone Number <span className="mr-1 text-red-600">*</span>
                </label>
                {errors.mobilePhone && (
                  <p className="mt-1 text-sm text-left text-red-600">
                    {errors.mobilePhone.message}
                  </p>
                )}
              </div>

              <div className="flex justify-center ml-20">
                <button
                  type="submit"
                  className="py-2 px-8 text-white font-medium rounded-2xl mb-5 focus:outline-none focus:ring-2"
                  style={{ backgroundColor: '#15415E', width: '250px' }}
                  disabled={pending}
                >


                  {pending ? (
                    <div className="flex items-center justify-center space-x-2">
                      <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                      </svg>

                    </div>
                  ) : (
                    "Send Verification Code"
                  )}
                </button>
              </div>
            </form>
          )}
          {verificationId && (

            <div className="flex justify-center">
              <form onSubmit={handleSubmit(sendVerificationCode)}>
                <div id="multifactor-enrollment"></div>
                <h2 className="text-2xl font-bold text-center mb-6 text-[#15415E]">
                  Verify Phone Number
                </h2>
                <p className="text-md font-bold text-center mb-8 text-[#A5BDC5]">Please enter the verification code sent to<br /> your phone number</p>
                <p className="text-md font-bold text-center mb-12 text-[#15415E]">{phoneNumber}

                  <button
                    onClick={() => setVerificationId()}
                    className="outline-none mr-1 mb-1 px-4 py-2 bg-transparent text-sm font-bold text-blue-500 hover:text-blue-600 uppercase focus:outline-none"
                    type="button"
                    disabled={pending}
                  >
                    <img
                      src="/images/editMFA.png"
                      alt="App Store"
                      className="w-4 h-4 mr-1"
                    />
                  </button>
                </p>

                <div className="flex justify-center my-10">
                  <input
                    className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
                    name="verificationCode"
                    type="hidden"
                    inputMode="numeric"
                    autoComplete="one-time-code"
                    ref={register({
                      required: "Please enter your confirmation code",
                    })}
                  />
                  <AuthCode
                    containerClassName="flex flex-row"
                    inputClassName="shadow-none m-1 w-14 border-solid border-r-0 border-l-0 border-t-0 border-b-3 border-[#15415E] xs:w-8 xs:text-center xs:text-sm xs:p-1"
                    alphanumeric="numeric"
                    ariaLabel="mobile confirmation code"
                    onChange={handleCodeChange}
                  //onChange={(code: number) => setValue("verificationCode", code)}
                  />
                  {errors.verificationCode && (
                    <p className="mt-1 text-sm text-red-600">
                      {errors.verificationCode.message}
                    </p>
                  )}
                </div>

                <div className="flex justify-center">
                  <button
                    type="submit"
                    className={`py-2 px-8 text-white font-medium rounded-2xl mb-5 focus:outline-none focus:ring-2 ${isCodeComplete ? "bg-[#15415E]" : "bg-[#F4F7F8] text-[#15415E]"
                      }`}
                    style={{ width: '250px' }}
                    disabled={pending}
                  >
                    {pending ? (
                      <div className="flex items-center justify-center space-x-2">
                        <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                        </svg>

                      </div>
                    ) : (
                      "Confirm Verification Code"
                    )}
                  </button>
                </div>

                <div className="flex justify-center">


                  {timeLeft > 0 ? (
                    <p className="text-[#A5BDC5] font-bold">
                      Resend code in {timeLeft} seconds
                    </p>
                  ) : (
                    <button
                      onClick={() => reRequestCode(phoneNumber || '')}
                      className="inline-block align-baseline justify-center outline-none mr-1 mb-1 px-6 py-2 bg-transparent text-md font-bold text-instant-teams-blue-Main focus:outline-none"
                      type="button"
                      disabled={pending}
                    >
                      Did not receive verification code? Resend
                    </button>
                  )}
                </div>
              </form>
            </div>
          )}

        </div>
      </>


      <ToastAlert />
    </>
  );
}

export default PasswordSettings;
