import { useState, useMemo } from "react";
import PlansPage from "./PlansPage";
import ElementsProvider from "./ElementsProvider";
import MonthlySubscriptionPreText from "./MonthlySubscriptionPreText";

type BuyCreditsProps = {
  currentSubscription?: string;
  isSignup?: boolean;
  employerId: string;
  onDone: () => void;
};

const BuyCredits = ({
  currentSubscription = "",
  isSignup = false,
  employerId = "",
  onDone,
}: BuyCreditsProps) => {
  const [selectedSubscription, setSelectedSubcription] = useState("");
  const [renderView, setRenderView] = useState();

  const handleContinue = (subscriptionText: string) => {
    setSelectedSubcription(subscriptionText);
    console.log(subscriptionText, "=======>subscriptionText");

    if (subscriptionText === "single") {
      setRenderView(1);
    } else if (subscriptionText === "monthly") {
      setRenderView(1);
    } else if (subscriptionText === "null") {
      onDone();
    }
  };

  const handleShowPayment = () => {
    setRenderView(1);
  };

  const renderSteps = (activeStep: any) => {
    switch (activeStep) {
      case 1:
        return (
          <div className="w-full">
            <ElementsProvider
              selectedSubscription={selectedSubscription}
              employerId={employerId}
              // priceId={"price_1QPpjfELIBAvshmEI5DonUnt"}
            />
          </div>
        );
      case 2:
        return (
          <div className="w-full">
            <MonthlySubscriptionPreText onContinue={handleShowPayment} />
          </div>
        );
      default:
        return (
          <div className="w-full flex flex-row justify-center">
            <PlansPage
              currentSubscription={currentSubscription}
              onContinue={handleContinue}
            />
          </div>
        );
    }
  };

  return <>{renderSteps(renderView)}</>;
};

export default BuyCredits;
