import React from 'react';
import BulletTextWrapper from '../CurrentPlan/BulletTextWrapper';
import DialogWrapper from '../../../../../custom-ui/dialog/Dialog';
import useModal from '../../../../../../hooks/useModal';
import ExclamationCloudSVG from '../../../../../custom-ui/icons/ExclamationCloudSVG';
import { convertTimestamp_subAdmin } from '../../util';
import { cancelStripeSubscription } from '../../../../../../callable-cloud-functions/cloudFunctions';
import { useEmployer } from '../../../../../../data/employer';
import { cancelAnnualSubscriptionOfEmployerThroughEmployerAdmin } from '../../../../../../callable-cloud-functions/cloudFunctions';
import ToastAlert from '../../../../../custom-ui/toast-alert/ToastAlert';
import toast from 'react-hot-toast';
import { useState } from 'react';

const AboutSubCurrentPlan_EmpProfile = ({ subcriptionData, empId }: any) => {
    const { isShowing: showCancelSubModal, toggle: toggleshowCancelSubModal } = useModal();
    const { data: employer } = useEmployer(empId ?? "");
    const { date, mAndY } = convertTimestamp_subAdmin(subcriptionData?.subscriptionExpiry) || {};
    const [loading, setLoading] = useState()
    const handleCancelPlan = () => {
        if (subcriptionData?.currentPlan === "monthly") {
            setLoading(true)
            cancelStripeSubscription({
                subscriptionId: employer?.stripeData?.subscriptionId,
                employerId: employer.id,
            })
                .then((res) => { toast.success("Canceled monthly subcription successfully"); toggleshowCancelSubModal(); setLoading(false) })
                .catch((err) => { toast.error("Having problem to cancel subcription"); toggleshowCancelSubModal(); setLoading(false) })

        } else {
            setLoading(true)
            cancelAnnualSubscriptionOfEmployerThroughEmployerAdmin({ employerId: employer.id })
                .then((res) => { toast.success("Canceled monthly subcription successfully"); toggleshowCancelSubModal(); setLoading(false) })
                .catch((err) => { toast.error("Having problem to cancel subcription"); toggleshowCancelSubModal(); setLoading(false) })

        }

    }


    return (
        <>
            <div className="  w-auto h-[236px] rounded-[15px] bg-gradient-to-r from-[#0B619A] to-[#4DAAE8] flex-1 flex flex-col text-[#FFFFFF] p-[20px]">
                <div className="flex flex-row justify-between items-center">
                    {subcriptionData?.currentPlan === "single" && (
                        <p className="font-[Gilroy-Bold] font-[400] text-[20px] leading-[24.76px] tracking-wider">

                            <span className="font-[Gilroy-Medium]  text-cetner  font-[400] text-[20px] leading-[24.26px] ">
                                Fuel Your Talent Pipeline with Ease
                            </span>
                        </p>
                    )}
                    {subcriptionData?.currentPlan === "monthly" && (
                        <p className="font-[Gilroy-Bold] font-[400] text-[20px] leading-[24.76px] tracking-wider">

                            <span className="font-[Gilroy-Medium]  text-cetner  font-[400] text-[20px] leading-[24.26px] ">
                                Empower Your Hiring Strategy, Month After Month
                            </span>{" "}
                         
                        </p>
                    )}
                    {subcriptionData?.currentPlan === "annual" && (
                        <p className="font-[Gilroy-Bold] font-[400] text-[20px] leading-[24.76px] tracking-wider">

                            <span className="font-[Gilroy-Medium]  text-cetner  font-[400] text-[20px] leading-[24.26px] ">
                                Your Gateway to Strategic Hiring Success
                            </span>
                        </p>
                    )}
                    {["monthly", "annual"].includes(subcriptionData?.currentPlan) && (
                        <button
                            className="underline font-[Gilroy-Bold] font-[400] text-[16px] leading-[19.81px] tracking-wider"
                            onClick={() => { toggleshowCancelSubModal(); }}
                        >
                            Cancel Plan
                        </button>
                    )}

                </div>

                <div className="flex flex-col flex-1 gap-[10px] font-[Gilroy-Regular] font-[400] text-[12px] leading-[14.4px] tracking-wider mt-[20px]">

                    {subcriptionData?.currentPlan === "single" && (
                        <>
                            <BulletTextWrapper>
                                Includes one 30-day job post
                            </BulletTextWrapper>
                            <BulletTextWrapper>
                            Utilize anytime within a year
                            </BulletTextWrapper>
                            <BulletTextWrapper>
                                Features employer profile and logo
                            </BulletTextWrapper>
                            <BulletTextWrapper>
                                Funnels candidates directly to your ATS
                            </BulletTextWrapper>
                        </>
                    )}

                    {subcriptionData?.currentPlan === "monthly" && (
                        <>
                            <BulletTextWrapper>
                                Includes three (3) 30-day job post credits each month

                            </BulletTextWrapper>
                            <BulletTextWrapper>
                                Credits refresh monthly to maintain active participation
                            </BulletTextWrapper>
                            <BulletTextWrapper>
                                Features employer profile and logo
                            </BulletTextWrapper>
                            <BulletTextWrapper>
                                Funnels candidates directly to your ATS
                            </BulletTextWrapper>
                            <BulletTextWrapper>
                                Automatic renewal and billing, cancel anytime
                            </BulletTextWrapper>
                        </>
                    )}

                    {subcriptionData?.currentPlan === "annual" && (
                        <>
                            <BulletTextWrapper>
                                Customizable Packages
                            </BulletTextWrapper>
                            <BulletTextWrapper>Seamless Renewals</BulletTextWrapper>
                            <BulletTextWrapper>
                                Expert Partnership & Sales Support
                            </BulletTextWrapper>
                        </>
                    )}

                </div>

                {/* <button className="underline font-[Gilroy-Bold] font-[400] text-[16px] leading-[19.81px] mb-[5px] tracking-wider">
                    See All Benefits
                </button> */}

            </div>
            {
                showCancelSubModal && (
                    <DialogWrapper title='' onClose={() => null}>
                        <>

                            <div className=' w-full flex flex-col justify-center items-center'>
                                <ExclamationCloudSVG />
                                <p className="w-auto font-[Gilroy-Bold] font-[400] text-[24px] leading-[29.71px] text-[#15415E] text-center">
                                    Are you sure you want to cancel your subscription?
                                </p>
                                <p className='mt-4 text-base  text-gray-400 text-center '>
                                    {`Are you sure you want to cancel this employer's subscription? The current plan benefits will remain active until
                                    ${date + " " + mAndY},
                                    and existing credits will not be affected. However, after [Next Billing Date],
                                    no additional credits will be allocated, and the subscription will not renew.`}
                                </p>
                            </div>
                            <div className="flex flex-row justify-center items-center gap-[15px] mt-[30px]">
                                <button
                                    className="flex justify-center items-center h-[60px] w-[200px] rounded-[70px] border-[1px] border-[#15415E] py-[25px] px-[60px] hover:border-[#1d5174] hover:bg-slate-200 text-[#15415E] text-[20px] leading-[24.76px] font-[400] font-[Gilroy-Bold]"
                                    onClick={toggleshowCancelSubModal}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="flex justify-center items-center h-[60px] w-[200px] py-[25px] px-[60px] rounded-[70px] bg-[#ED3535] text-[#FFFFFF] text-[20px] leading-[24.76px] font-[400] font-[Gilroy-Bold]"
                                    onClick={handleCancelPlan}
                                >
                                    {loading ? "Proceeding..." : "Proceed"}
                                </button>
                            </div>
                        </>
                    </DialogWrapper>
                )
            }
            <ToastAlert />
        </>

    );
}

export default AboutSubCurrentPlan_EmpProfile;
