export function formatTimeDifference(makeLiveDate) {
    const currentDate = new Date();
    const liveDate = new Date(makeLiveDate?.seconds * 1000);
    const diffInMilliseconds = currentDate - liveDate;
    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    if (diffInHours < 24) {
        return diffInHours > 0 ? `${diffInHours}h ago` : `${Math.floor(diffInMinutes)}m ago`;
    } else {
        return diffInDays <= 7 ? `${diffInDays}d ago` : `${diffInDays}d ago`;
    }
}

export function convertTimestamp_subAdmin(timestamp) {

    const dateObject = new Date(timestamp * 1000);
    const day = dateObject.getDate();
    const month = dateObject.toLocaleString('default', { month: 'short' }); // Get abbreviated month name
    const year = dateObject.getFullYear();


    const result = {
        date: day,
        mAndY: `${month} ${year}`
    };

    return result;
}