import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useNavigate } from "react-router-dom";
import { AddMoreCreditSchema } from "./AddMoreCreditsSchema";
import DateGeneric from "../../../../../custom-ui/old-ui/DateGeneric";
import ToastAlert from "../../../../../custom-ui/toast-alert/ToastAlert";
import FloatingLabelInput from "../../Component-UI/FloatPlaceholderInputField";
import { addMoreCreditsThroughEmployerAdmin } from "../../../../../../callable-cloud-functions/cloudFunctions";
import toast from "react-hot-toast";

function AddMoreCredtis({ empId, toggleshowAddMoreCreditsModal }: any) {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState()
    const [formSchema, setFormSchema] = useState(AddMoreCreditSchema(false));


    type FormValues = z.infer<ReturnType<typeof AddMoreCreditSchema>>;
    const form = useForm<FormValues>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            creditsExpDate: "",
            numOfCredits: "",

        },
        reValidateMode: "onBlur",
    });



    const {
        handleSubmit,
        setValue,
        control,
        reset,
        formState: { errors, isSubmitting },
    } = form;

    const onSubmit = async ({ ...values }: FormValues) => {
        console.log(values)
        setLoading(true)
        addMoreCreditsThroughEmployerAdmin({
            employerId: empId,
            noOfCredits: values?.numOfCredits,
            creditExpiryDate: values?.creditsExpDate
        }).then((res) => {
            toast.success("Credits Added Successfully")
            toggleshowAddMoreCreditsModal()
            setLoading(false)
        }).catch((error) => {
            toast.error("Having Problem to add Credits")
            setLoading(false)
        })



    };
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="w-full  flex flex-col justify-center items-center">
                    <div className="w-full flex flex-col justify-center items-center">

                        <div className=" w-full mt-7">
                            <Controller
                                control={control}
                                name="creditsExpDate"
                                render={(field) => (
                                    <DateGeneric
                                        {...field}
                                        display="Credit Expiry Date"
                                        timestamp={true}

                                        error={errors?.creditsExpDate?.message}
                                        required
                                    />
                                )}
                            />
                        </div>


                        {error && <p className="text-red-500  text-base" >{error}</p>}
                        <div className=" w-full items-center">
                            <Controller
                                control={control}
                                name="numOfCredits"
                                render={(field) => (
                                    <FloatingLabelInput
                                        {...field}
                                        display="Number of Credits"
                                        placeholder="Enter number of Credits"

                                        error={""}
                                        required
                                    />
                                )}
                            />
                            {errors?.numOfCredits?.message && <p className="text-red-500 mt-[-20px]  text-sm" >{errors?.numOfCredits?.message}</p>}
                        </div>
                        {/*  */}


                    </div>
                </div>
                <div className="mt-2 first-letter:w-full flex flex-row justify-center">

                    <div className="flex justify-center items-center">

                        <button
                            type="submit"
                            disabled={loading}
                            className="h-[50px] w-[210px] rounded-[70px] bg-[#15415E] px-5 py-3 hover:bg-[#1d5174] text-[#FFFFFF] text-[16px] leading-[19.6px] font-[400]"
                        >
                            {loading ? "Adding Credits..." : "Add Credits"}
                        </button>
                    </div>
                </div>
            </form>
            <ToastAlert />
        </>
    );
}

export default AddMoreCredtis;
