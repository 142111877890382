import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import DateGeneric from "../../../../../custom-ui/old-ui/DateGeneric";
import ToastAlert from "../../../../../custom-ui/toast-alert/ToastAlert";
import FloatingLabelInput from "../../Component-UI/FloatPlaceholderInputField";
import toast from "react-hot-toast";
import { assignAnnualSubscriptionToEmployer } from "../../../../../../callable-cloud-functions/cloudFunctions";


export const AddAnnualPlanSchema = (isSubmittingJobFirstTime: boolean) => {
    return z.object({
        includeDate: z.boolean().default(true),
        startDate: z
            .union([
                z.date(),
                z
                    .string({ required_error: "Start Date is required" })
                    .min(1, { message: "Start Date is required" })
                    .datetime({ offset: true })
                    .optional(),
            ])
            .optional(),
        orderNumber: z.string().optional(), // Clearly optional
    });
};


function AddAnnualSubcriptionForm({ empId, toggleShowSubcriptionFormModal, selectedPlan }: any) {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState()
    const [formSchema, setFormSchema] = useState(AddAnnualPlanSchema(false));


    type FormValues = z.infer<ReturnType<typeof AddAnnualPlanSchema>>;
    const form = useForm<FormValues>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            startDate: "",
            orderNumber: "",
        },
        reValidateMode: "onBlur",
    });



    const {
        handleSubmit,
        control,
        formState: { errors, isSubmitting },
    } = form;

    const onSubmit = async ({ ...values }: FormValues) => {
        setLoading(true)
        await assignAnnualSubscriptionToEmployer({
            employerId:empId,
            jobsPerMonth: selectedPlan,
            orderNumber: values?.orderNumber,
            startDate: values?.startDate
        }).then((res: any) => {
            toast.success("Annual plan added successfully")
            toggleShowSubcriptionFormModal();
            setLoading(false)
        }).catch((err:any) => {
            toast.error("Having problem with adding annual plan  please try again")
            setLoading(false)
        })


    };
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="w-full  flex flex-col justify-center items-center">
                    <div className="w-full flex flex-col justify-center items-center">

                        <div className=" w-full mt-7">
                            <Controller
                                control={control}
                                name="startDate"
                                render={(field) => (
                                    <DateGeneric
                                        {...field}
                                        display="Start Date"
                                        timestamp={true}

                                        error={errors?.startDate?.message}
                                        required
                                    />
                                )}
                            />
                        </div>


                        {error && <p className="text-red-500  text-base" >{error}</p>}
                        <div className=" w-full items-center">
                            <Controller
                                control={control}
                                name="orderNumber"
                                render={(field) => (
                                    <FloatingLabelInput
                                        {...field}
                                        display="Order Number"
                                        placeholder="Enter order number"

                                        error={""}

                                    />
                                )}
                            />
                            {errors?.orderNumber?.message && <p className="text-red-500 mt-[-20px]  text-sm" >{errors?.orderNumber?.message}</p>}
                        </div>
                        {/*  */}


                    </div>
                </div>
                <div className="mt-2 first-letter:w-full flex flex-row justify-center">

                    <div className="flex justify-center items-center">

                        <button
                            type="submit"
                            disabled={loading}
                            className="h-[50px] w-[210px] rounded-[70px] bg-[#15415E] px-5 py-3 hover:bg-[#1d5174] text-[#FFFFFF] text-[16px] leading-[19.6px] font-[400]"
                        >
                            {loading ? "Adding Annual Plan..." : "Continue"}
                        </button>
                    </div>
                </div>
            </form>
            <ToastAlert />
        </>
    );
}

export default AddAnnualSubcriptionForm;
