import * as z from "zod";

export const AddMoreCreditSchema = (isSubmittingJobFirstTime: boolean) => {
  return z.object({
    includeDate: z.boolean().default(true), 
    creditsExpDate: z
      .union([z.date(), z.string({required_error: "Start Date is required",}).min(1, {
        message: "Start Date is required",
      }).datetime({ offset: true }).optional()])
      .optional(),
  
      numOfCredits: z
           .union([
             z.string().optional(),
             z
               .number({
                 invalid_type_error: "No of Credits field is required",
               })
               .min(1, {
                 message: "There should be minimum of 1 Credits",
               })
               .max(500, {
                 message: "There should be a maximum of 500 Credits",
               }),
           ])
           .refine((value) => value !== "", {
             message: "No of Credits is required",
           }),
  });
};
