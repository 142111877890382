/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useState, useEffect, forwardRef } from "react";
import { Link } from "react-router-dom";
import {
  useEmployer,
  useEmployerStatus,
  useEmployerPrimary,
  useEmployerUsers,
  useEmployerDepartments,
  useEmployerJobs,
  getEmployerAssignees,
  useEmployerJobsForEmployerNew
} from "../../../data/employer";
import { useAllListingsJobForEmp } from "../../../data/listings";
import { useEmployeesByEmployer } from "../../../data/employer";
import Tooltip from "../../custom-ui/tooltip/tooltip";
import { TailwindHoverEnlarge } from "../../../util/tailwind/tailwind-styles";

const EmployerListItem_NewUI = forwardRef((row: any, ref: any) => {
  const {
    data: employer = {},
    status: employerStatus = "",
    error: employerError,
  } = useEmployer(row?.row?.hit?.objectID);

  const {
    data: statusData = {},
    status: statusDataStatus = "",
    error: statusDataError,
  } = useEmployerStatus(employer?.status);

  const {
    data: primaryData = {},
    status: primaryStatus = "",
    error: primaryError,
  } = useEmployerPrimary(employer?.id);

  const {
    data: userData = {},
    status: userStatus = "",
    error: userError,
  } = useEmployerUsers(employer?.id);

  const {
    data: departmentData = {},
    status: departmentStatus = "",
    error: departmentError,
  } = useEmployerDepartments(employer?.id);

  const {
    data: jobsData = [],
    status: jobsStatus = "",
    error: jobsError,
  } = useAllListingsJobForEmp(employer?.id);

  const { data: employeeData = {} } = useEmployeesByEmployer(employer?.id);

  const [annualRev, setAnnualRev] = useState(
    employer?.intel?.metrics?.annualRevenue || 0,
  );

  const colorJobListingVariants: any = {
    closed: "bg-instant-teams-tags-CREAM text-black",
    dormant: "bg-instant-teams-tags-ROSE",
    active: "bg-instant-teams-tags-GREEN",
    invited: "bg-instant-teams-tags-GOLD",
    lead: "bg-instant-teams-tags-TEAL",
    removed: "bg-instant-teams-tags-ORANGE",
    under_review: "bg-instant-teams-tags-BLUE",
  };

  const [tac, setTac] = useState("");
  const [am, setAm] = useState("");
  const [creator, setCreator] = useState("");

  useEffect(() => {
    const rev = employer?.intel?.metrics?.annualRevenue || 0;
    setAnnualRev(rev);
  }, [employer, departmentData]);

  useEffect(() => {
    async function fetchData(employer: any) {
      const assignees:
        | { am: string; tac: string; creator: string }
        | undefined = await getEmployerAssignees(employer?.id);
      setCreator(assignees?.creator);
      setAm(assignees?.am);
      setTac(assignees?.tac);
    }
    fetchData(employer);
  }, [employer]);

  return (
    <div
      id={employer?.id}
      // transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 duration-300
      // className=" hover:scale-105 mb-2 first:rounded-xl border shadow-md bg-white h-full"
      className={
        TailwindHoverEnlarge() +
        "mb-2 first:rounded-xl border shadow-md bg-white h-full"
      }
    >
      <Link to={`/employers-new/${employer?.id}/openings`}>
        <div className="rounded-t-xl text-instant-teams-teal-Main pt-4">
          <div className="m-auto w-3/4 object-contain">
            <img
              className="object-scale-down max-h-20 h-20 object-center resize rounded-md mx-auto px-4"
              src={employer?.logo || "/images/default-employer.jpg"}
              alt={employer?.displayName}
            />
            <p className="text-xs pt-1 font-bold m-auto text-center">
              {employer?.displayName}
            </p>
          </div>
        </div>
        <div>
          <Tooltip text={statusData?.description || "Current Status"}>
            <div
              className={`items-center w-3/4 mt-5 rounded-md text-white mx-auto p-1 ${
                colorJobListingVariants[statusData?.id] ||
                "bg-instant-teams-purple-Main"
              }`}
            >
              <p className="text-center">{statusData?.label}</p>
            </div>
          </Tooltip>
          <div className="items-center bg-white w-full px-4 mt-5">
            <div className="grid grid-cols-2 space-x-2 items-center mx-auto text-center">
              <Tooltip text="Employer Users">
                <div className="rounded-md bg-instant-teams-tags-A1 text-white px-1">
                  <span className="text-instant-teams-tags-A2">
                    {userData.length}
                  </span>
                </div>
              </Tooltip>
              {/* <Tooltip text="Employer Departments">
                <div className="rounded-md bg-instant-teams-tags-B1 text-white px-1">
                  <span className="text-instant-teams-tags-B2">
                    {departmentData?.length > 0 ? departmentData.length : 0}
                  </span>
                </div>
              </Tooltip> */}
              <Tooltip text="Total Jobs">
                <div className="rounded-md bg-instant-teams-tags-C1 text-white px-1">
                  <span className="text-instant-teams-tags-C2">
                    {jobsData? jobsData?.length : 0}
                  </span>
                </div>
              </Tooltip>
              {/* <Tooltip text="Employed">
                <div className="rounded-md bg-instant-teams-tags-D1 text-white px-1">
                  <span className="text-instant-teams-tags-D2">
                    {employeeData?.length}
                  </span>
                </div>
              </Tooltip> */}
            </div>
          </div>
          <div className="bg-white w-full px-4 mt-4 text-sm text-center">
            <b className="pr-2">Account Owner:</b>
            {creator || "Unknown"}
          </div>
          {/* <div className="bg-white w-full px-4 mt-2 text-sm text-center">
            <b className="pr-2">AM:</b>
            {am || "Not Assigned"}
          </div>
          <div className="bg-white w-full px-4 mt-2 text-sm text-center">
            <b className="pr-2">TAC:</b>
            {tac || "Not Assigned"}
          </div> */}
          <div className="bg-white w-full px-4 mt-2 mb-4 text-sm text-center">
            <b className="pr-2">POC:</b>
            {primaryData[0]?.name || "Not Assigned"}
          </div>
        </div>
      </Link>
    </div>
  );
});

export default EmployerListItem_NewUI;
