import * as z from "zod";

export const getSpeakWithAccountExecutiveSchema = () => {
  return z
    .object({
      fullName: z
        .string({
          required_error: "Full Name field is required",
        })
        .min(1, {
          message: "Full Name is required",
        }),
      email: z
        .string({
          required_error: "Email field is required",
        })
        .email({
          message:
            "Please enter a valid email address. Example: username@example.com.",
        })
        .min(1, {
          message: "Email is required",
        }),
      phoneNumber: z
        .string()
        .min(1, {
          message: "Phone Number is required",
        })
        .refine((value: any) => /^\+?[1-9]\d{9,14}$/.test(value), {
          message:
            "Please enter a valid phone number. The format should be [+][country code][number].",
        }),
      message: z
        .string({
          required_error: "Message field is required",
        })
        .min(1, {
          message: "Message is required",
        }),
    })
    .superRefine((data, ctx) => {});
};
