import { useState, useEffect } from "react";
import CompanyProfileCard from "./CompanyProfileCardWrapper";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import toast from "react-hot-toast";
import { getCompanyInformationSchema } from "../../../../../../schemas/CompanyInformationSchema";
import { EmployerNew } from "../../../../../../dataTypes/Employer";
import {
  updateEmployerNew,
  useEmployer,
} from "../../../../../../data/employer";
import ToastAlert from "../../../../../custom-ui/toast-alert/ToastAlert";
import { useAuth } from "../../../../../../providers/auth";
import TextArea from "../../../../../custom-ui/new-ui/TextArea";
import { useParams } from "react-router-dom";
type CompanyInformationCardProps = {};

const CompanyInformationCard = ({}: CompanyInformationCardProps) => {
  const { user } = useAuth();
  const params = useParams();
  const employerId:any = user.claims.type==="A"? params?.employerId: user.claims.employerId;
  const { data: employer } = useEmployer(employerId ?? "");

  // @ts-ignore
  const [isInitialized, setIsInitialized] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [formSchema, setFormSchema] = useState(getCompanyInformationSchema());

  type FormValues = z.infer<ReturnType<typeof getCompanyInformationSchema>>;

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      intro: employer?.profile?.intro ?? "",
      overview: employer?.profile?.overview ?? "",
      mission: employer?.profile?.mission ?? "",
    },
    reValidateMode: "onBlur",
  });

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = form;

  const onSubmit = async ({ ...values }: FormValues) => {
    const { intro, overview, mission, ...restValues } = values;

    const employerCollectionData: EmployerNew = {
      displayName: employer?.displayName ?? null,
      location: employer?.location ?? null,
      companySize: employer?.companySize ?? null,
      hiringNeeds: employer?.hiringNeeds ?? null,
      jobsToFill: employer?.jobsToFill ?? null,
      profile: {
        url: employer?.profile?.url ?? null,
        sector: employer?.profile?.sector ?? null,
        facebook: employer?.profile?.facebook ?? null,
        linkedin: employer?.profile?.linkedin ?? null,
        twitter: employer?.profile?.twitter ?? null,
        intro: intro ?? null,
        overview: overview ?? null,
        mission: mission ?? null,
      },
    };

    await updateEmployerNew(employerId, employerCollectionData)
      .then(() => {
        toast.success("Company Information updated successfully");
        setIsEditing(!isEditing);
      })
      .catch(() => {
        toast.error("There was an error updating Company Information");
      });
  };

  const handleButtonEdit = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    reset();
    setIntialValuesToForm(employer);
    setIsEditing(false);
  };

  const setIntialValuesToForm = (employerData: any) => {
    if (!employerData || (employerData && !Object.keys(employerData).length))
      return;

    setValue("intro", employer?.profile?.intro ?? "");
    setValue("overview", employer?.profile?.overview ?? "");
    setValue("mission", employer?.profile?.mission ?? "");
  };

  useEffect(() => {
    if (employer && !isInitialized) {
      setIntialValuesToForm(employer);
      setIsInitialized(true);
    }
  }, [employer, isInitialized]);

  return (
    <>
      <CompanyProfileCard
        title="Company Information"
        onButtonClick={isEditing ? handleSubmit(onSubmit) : handleButtonEdit}
        onCancelClick={handleCancelClick}
        isEditing={isEditing}
        isSubmitting={isSubmitting}
        className="w-full"
      >
        <div className="flex flex-col gap-[30px] mt-[30px]">
          <form>
            <div className="flex flex-col gap-[30px]">
              <Controller
                control={control}
                name="intro"
                render={(field) => (
                  <TextArea
                    {...field}
                    display="About the Company"
                    placeholder="Please write here"
                    readOnly={!isEditing}
                    error={errors?.intro?.message}
                    required={isEditing}
                    labelClassName="text-[18px] leading-[24.5px] mb-[10px]"
                    maxLength={500}
                    isViewMode={!isEditing}
                  />
                )}
              />

              <Controller
                control={control}
                name="overview"
                render={(field) => (
                  <TextArea
                    {...field}
                    display="Overview"
                    placeholder="Please write here"
                    readOnly={!isEditing}
                    error={errors?.overview?.message}
                    labelClassName="text-[18px] leading-[24.5px] mb-[10px]"
                    maxLength={500}
                    isViewMode={!isEditing}
                  />
                )}
              />

              <Controller
                control={control}
                name="mission"
                render={(field) => (
                  <TextArea
                    {...field}
                    display="Mission"
                    placeholder="Please write here"
                    readOnly={!isEditing}
                    error={errors?.mission?.message}
                    labelClassName="text-[18px] leading-[24.5px] mb-[10px]"
                    maxLength={500}
                    isViewMode={!isEditing}
                  />
                )}
              />
            </div>
          </form>
        </div>
      </CompanyProfileCard>

      <ToastAlert />
    </>
  );
};

export default CompanyInformationCard;
