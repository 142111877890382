import { forwardRef } from "react";
import { Link } from "react-router-dom";
import { useFindJobState } from "../../../../data/status";
import { useLookupCode } from "../../../../data/lookup";
import Tooltip from "../../../custom-ui/tooltip/tooltip";
import { TailwindHoverEnlarge } from "../../../../util/tailwind/tailwind-styles";
import DisplayItemLookup from "../../../custom-ui/display/displayItemLookups";

const EmpJobNewListingLineComponent_NewUI = 
    ({ row }: { row: any }) => {
    const { data: solution } = useLookupCode(row?.solutionType);
    const { data: statusData } = useFindJobState(row?.jobState);
    const colorJobListingVariants: any = {
      draft: "bg-instant-teams-tags-CREAM text-black",
      live: "bg-instant-teams-tags-GREEN",
      on_hold: "bg-instant-teams-tags-ROSE",
      closed_filled: "bg-instant-teams-tags-LBLUE",
      closed_cancelled: "bg-instant-teams-gray-D1",
    };

    return (
      <Link
        to={`/jobs-new/${row?.id}/${row?.companyId}/information`}
        className="cursor-pointer bg-white h-full"
      >
        <div
    
          className={
            TailwindHoverEnlarge(105) +
            "items-center w-full rounded-lg relative shadow-lg pb-2"
          }
        >
          <div className="items-center bg-instant-teams-teal-Main p-5 rounded-t-lg w-full min-h-max h-42">
            <div className="m-auto">
              <img
                className="object-scale-down max-h-12 min-h-12 w-full resize rounded-md mx-auto mb-2 my-auto"
                src={row?.companyLogo || "/images/default-employer.jpg"}
                alt={row?.companyFullName}
              />
            </div>
            <div className="w-full text-white pl-2 text-center mb-2">
              <p className=" text-sm">{row?.companyFullName}</p>
              <p className=" text-xl whitespace-nowrap text-ellipsis overflow-hidden">
                {row?.jobName}
              </p>
              <p className=" text-sm">{solution?.[0]?.label || "Not Set"}</p>
            </div>
            {/* <span className="inline-block px-2 py-1 text-xs font-bold leading-none text-instant-teams-teal-Main bg-white rounded-full absolute top-4 left-4">
              <Tooltip text="Positions Created">
                <p>{row?.hit?.summaryCount?.positions}</p>
              </Tooltip>
            </span> */}
            <span className="inline-block px-2 py-1 text-xs font-bold leading-none text-instant-teams-teal-Main bg-white rounded-full absolute top-4 right-4">
              <Tooltip text="Number of Openings">
                <p>{row?.openings || 1}</p>
              </Tooltip>
            </span>
          </div>
          <Tooltip text={statusData?.description || "Current Status"}>
            <div
              className={`items-center w-3/4 mt-5 rounded-md text-white mx-auto p-1 ${
                colorJobListingVariants[statusData?.id] ||
                "bg-instant-teams-purple-Main"
              }`}
            >
              <p className="text-center">{statusData?.label}</p>
            </div>
          </Tooltip>

          <div className="text-center text-sm mt-4">
            <DisplayItemLookup value={row?.workTerm} group={"workTerm"} />
          </div>
          <div className="text-center text-sm mt-1">
            <DisplayItemLookup value={row?.workType} group={"workType"} />
          </div>

          <div className="text-center text-sm mt-1">
            <DisplayItemLookup
              value={row?.workSettings}
              group={"workSetting"}
            />
          </div>
          <div className="text-center text-sm mt-1">
            Hours Per Week:{" "}
            {row?.hoursPerWeek ? (
              <>
                {row?.hoursPerWeek?.min} - {row?.hoursPerWeek?.max}
              </>
            ) : (
              <>Not Set</>
            )}
          </div>
          <div className="text-center text-sm mt-1 mb-2">
            {row?.payRate?.type === "hourly" && <span>Hourly</span>}
            {row?.payRate?.type === "salary" && <span>Yearly</span>}
            {row?.payRate?.type === "commission" && (
              <span>Commission</span>
            )}
            {row?.payRate?.type === "unpaid_internship" && (
              <span>Free Internship</span>
            )}
            {!["hourly", "salary", "commission", "unpaid_internship"].includes(
              row?.payRate?.type,
            ) && <span>Not Set</span>}
          </div>
        </div>
      </Link>
    );
  }


export default EmpJobNewListingLineComponent_NewUI;
