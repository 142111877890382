import toast from "react-hot-toast";
import useModal from "../../../hooks/useModal";
import CreateEmployerForm from "../CreateEmployerForm";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  SearchBox,
  CurrentRefinements,
  RefinementList,
  ClearRefinements,
  Hits,
  HitsPerPage,
  Pagination,
  RefinementListProps,
  useInstantSearch,
} from "react-instantsearch-hooks-web";
import ToastAlert from "../../custom-ui/toast-alert/ToastAlert";
import EmployerListItem from "./EmployerListItem";
import { getLabel } from "../../../util/search/getLabel";
import { useNavigate } from "react-router-dom";
import EmployerListItem_NewUI from "./EmployerListItem_NewUI";

const transformEmployerStatus: RefinementListProps["transformItems"] = (
  items,
) => {
  // Define the labels you want to exclude
  const excludedLabels = ["under_review","removed","invited","lead", ""];

  // Filter out unwanted items
  items = items
    .filter((item: any) => !excludedLabels.includes(item.label))
    .map((item: any) => ({
      ...item,
      label: getLabel(item.label, "employerStatus"),
    }));
  
  return items;
};

// Temporary Component
function EmployerList_NewUI() {
  const navigate = useNavigate();
  const { isShowing, toggle } = useModal();
  const { refresh, results } = useInstantSearch();
  const hasHits = results?.nbHits > 0;
  const handleDone = (
    message: string | undefined,
    employerId: string | undefined,
  ) => {
    toggle();

    setTimeout(() => {
      refresh();
    }, 5000);
    if (message !== undefined) {
      toast.success(message);
    }
    if (employerId !== undefined) {
      navigate(`/employers/${employerId}/overview`);
    }
  };

  return (
    <div className="w-full grid grid-cols-5 gap-4">
      <div className="col-span-1 ml-12 mr-4">
        {/* <button
          className="w-full h-10 mb-4 text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
          onClick={toggle}
        >
          Create New Employer
        </button> */}
        <SearchBox
          placeholder="Keyword Search"
          classNames={{
            input: "w-full",
            submitIcon: "hidden",
            resetIcon: "hidden",
            submit: "hidden",
          }}
        />
        <CurrentRefinements />
      {hasHits&& " Filter by Status"}
        <RefinementList
          attribute="status"
          operator="and"
          classNames={{
            root: "capitalize",
            showMore: " text-instant-teams-blue-Main text-xs",
          }}
          limit={5}
          showMore={false}
          transformItems={transformEmployerStatus}
        />
        {hasHits && <ClearRefinements
          translations={{ resetButtonText: "Clear Filters" }}
          classNames={{
            button:
              " bg-instant-teams-teal-Main text-white p-2 rounded mt-5 text-center w-full",
          }}
        />}
      </div>
      <div className="col-span-4 w-[90%] mb-36">
        <div className="flex justify-end w-[100%]">
          <div className="flex items-center">
            <div className=" pr-4">Results per page:</div>
            <HitsPerPage
              items={[
                { label: "10", value: 10 },
                { label: "25", value: 25 },
                { label: "50", value: 50, default: true },
                { label: "100", value: 100 },
              ]}
              classNames={{ select: "text-xs h-6 py-0" }}
            />
          </div>
        </div>
        <Hits
          hitComponent={(hit: any) => <EmployerListItem_NewUI row={hit} />}
          classNames={{
            root: "ais-InfiniteHits",
            list: "ais-InfiniteHits-list grid grid-cols-5 gap-4 w-full mt-4",
          }}
        />
        <div className="flex justify-center">
          <div className="mt-12">
            <Pagination
              padding={1}
              classNames={{
                list: "flex",
                item: "h-8 w-12 text-center rounded-m rounded mx-1 bg-white w-full",
                selectedItem: "scale-125 border-black",
              }}
            />
          </div>
        </div>
      </div>
      {isShowing && <CreateEmployerForm onDone={handleDone} />}
      <ToastAlert />
    </div>
  );
}

export default EmployerList_NewUI;
