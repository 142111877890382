import { cn } from "../../../../../../util/cn";
import CrossSVG from "../../../../../custom-ui/icons/CrossSVG";
import PencilSVG from "../../../../../custom-ui/icons/PencilSVG";
import TickSVG from "../../../../../custom-ui/icons/TickSVG";

type CompanyProfileCardProps = {
  children: any;
  title: string;
  className?: string;
  isEditing: boolean;
  isSubmitting: boolean;
  onButtonClick: () => void;
  onCancelClick: () => void;
};

const CompanyProfileCard = ({
  children,
  title,
  className = "",
  isEditing = false,
  isSubmitting = false,
  onButtonClick,
  onCancelClick,
}: CompanyProfileCardProps) => {
  return (
    <div
      className={cn(
        "flex flex-col w-1/2  lg:w-full md:w-full bg-[#FFFFFF] h-auto min-h-[200px] rounded-[20px] p-[30px]",
        className,
      )}
      style={{
        boxShadow: "0 0 34px rgba(30, 89, 109, 0.04)",
      }}
    >
      <div className="flex flex-row justify-between items-center">
        <p className="font-[Gilroy-Bold] font-[400] text-[20px] leading-[25px] text-[#15415E] uppercase tracking-wide">
          {title}
        </p>

        <div className="flex flex-row gap-[10px]">
          {isEditing && (
            <button
              className={cn(
                "bg-[#ffffff] text-[#15415E] flex justify-center items-center rounded-full w-[50px] h-[50px] border-[1px] border-[#15415E] hover:bg-slate-200",
              )}
              onClick={onCancelClick}
              disabled={isSubmitting}
            >
              <CrossSVG />
            </button>
          )}

          <button
            className={cn(
              "bg-[#15415E] text-white flex justify-center items-center  rounded-full w-[50px] h-[50px]",
            )}
            onClick={onButtonClick}
            disabled={isSubmitting}
          >
            {isEditing ? <TickSVG /> : <PencilSVG />}
          </button>
        </div>
      </div>
      {children}
    </div>
  );
};

export default CompanyProfileCard;
